<template>
    <div id="Pricing" class=" text-dark container-fluid bg-white shadow-sm px-3 mb-5 rounded border-box">

        <div class="row">

            <div class="col-12 my-2">
                <div class="row bg-lightgray py-3">
                    <div class="col-3 opensans-bold">
                        <ki-input @input="search"
                            label="Buscar:"
                            v-model="search_query"
                            placeholder="Ej: Rafael Pérez"
                            full>

                        </ki-input>
                    </div>
                </div>
            </div>

            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p class="text-center opensans-bold">
                    Ha ocurrido un error intente más tarde
                </p>
            </div>

            <div class="col-12 py-3" v-if="!loading && !fetch_error && !empty">
                <ki-table :data="pricing_table_data"
                    :allow_headers="pricing_table_headers"
                    no_pagination color_body="white" color_headers="lightgray"
                    full>
                    <template v-slot:details="data">
                        <button @click.stop="showPricing(data.item)"
                            class="btn btn-white pills"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:to_sale="data">
                        <button @click.stop="selectPricing(data.item)" v-if="!data.item.id_sale"
                            class="btn pills text-white opensans-bold"
                            style="background: #198754"
                            type="button">
                            Venta
                        </button>
                   
                         <button v-else
                            class="btn pills text-white opensans-bold"
                            style="background: #198754"
                            :disabled="true"
                            type="button">
                            Venta completada
                        </button> 
                    </template>
                </ki-table>
            </div>

            <!----- Paginator -->
            <div class="col-12 pb-3">
                <div class="row justify-content-end align-items-center">
                    <div class="col d-flex">
                        <label class="mr-2">Productos por página: </label>
                        <ki-input @keyup.enter="updateRows"
                            v-model="rows">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prevPage"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                            variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="goToPage"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="nextPage"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="last_page">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  pages  }}
                        </p>
                    </div>
                </div>
            </div>

        </div>

        <!--Modals-->
        <ki-modal v-if="details_modal">
            <div class="row bg-white position-relative p-3" style="border-radius: 15px">
                <button @click.stop="details_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 25px; height: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px;"
                    type="button">
                    X
                </button>

                <div class="col-12 opensans-bold">
                    <p>
                        Detalles de la cotización
                    </p>
                    <hr>
                </div>

                <div class="col-12 p-0">
                    <ki-table :data="detail_table_data"
                        :allow_headers="detail_table_headers"
                        no_pagination color_body="white" color_headers="lightgray">
                    </ki-table>
                </div>

                <div class="col-12 py-2 bg-lightgray my-2"
                    style="border-radius: 10px;">
                    <div class="row opensans-bold">
                        <div class="col-4">
                            <ki-input label="Subtotal:"
                                :disabled="true"
                                v-model="subtotal"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-4">
                            <ki-input label="IVA:"
                                :disabled="true"
                                v-model="iva"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-4">
                            <ki-input label="Total:"
                                :disabled="true"
                                v-model="total"
                                full>
                            </ki-input>
                        </div>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="details_modal = false;"
                        class="btn btn-primary text-white pills"
                        type="button">
                        Aceptar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="sale_modal">
            <div class="row bg-white position-relative" style="border-radius: 15px;">
                <button @click.stop="sale_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 25px; height: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.5); top: -10px; right: -10px;"
                    type="button">
                    X
                </button>
                <div class="col-12 p-3 text-center">
                    <p>
                        ¿Cargar los datos de esta cotización a la venta?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center py-2">
                    <button @click.stop="sale_modal = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="processPricing"
                        class="ml-5 btn btn-primary text-white pills"
                        type="button">
                        Aceptar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>

    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                search_query: null,
                pricing_table_headers: [
                    { key: 'email', label: 'Correo eléctronico'},
                    { key: 'total', label: 'Total'},
                    { key: 'details'},
                    { key: 'to_sale'}
                ],
                pricing_table_data: [],
                detail_table_headers: [
                    { key: 'quantity', label: 'Cantidad'},
                    { key: 'product_name', label: 'Nombre'},
                    { key: 'price', label: 'Precio'},
                    { key: 'discount', label: 'Descuento'},
                    { key: 'total', label: 'Total'},
                ],
                detail_table_data: [],
                details_modal: false,
                sale_modal: false,
                fetch_error: false,
                loading: true,
                current_page: 1,
                rows: 10,
                empty: false,
                id_pricing: null,
                subtotal: null,
                iva: null,
                total: null,
                actions: null
            }
        },
        computed: {
            ...mapGetters({
                getPricings: 'pricing_module/getPricings',
                getPricing: 'pricing_module/getPricing',
                pages: 'pricing_module/getPages',
                last_page: 'pricing_module/getLastPage',
                getAccess: 'getAccess'
            })
        },
        methods: {
            //helpers
            composeTableData() {
                this.pricing_table_data = this.pricing_table_data.map( pricing => {
                    return { 
                        ...pricing,
                        details: '-',
                        to_sale: '-'
                    }
                })
            },
            async search() {
                
                if(_.isNull(this.search_query) || _.isEmpty(this.search_query)) {

                    await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: this.current_page, rows: this.rows})
                    this.pricing_table_data = this.getPricings
                    this.pricing_table_data = this.pricing_table_data.map( pricing => {
                        return { 
                            ...pricing,
                            details: '-',
                            to_sale: '-'
                        }
                    })

                    this.empty = false
                } 
                
                else {
                    let options = {
                        datatable: true, 
                        page: this.current_page, 
                        rows: this.rows,
                        email: this.search_query
                    }

                    await this.$store.dispatch('pricing_module/listPricings', options)
                    
                    if(this.getPricings == null) {
                        this.empty = true
                        return
                    }
                    
                    this.pricing_table_data = this.getPricings
                    this.pricing_table_data = this.pricing_table_data.map( pricing => {
                        return { 
                            ...pricing,
                            details: '-',
                            to_sale: '-'
                        }
                    })
                }

                
            },
            async showPricing(data) {
                let access = this.actions.find( action => action.name = 'view')
                
                if(!access.has_permission) {
                    this.$store.commit('setToast', {
                        header: 'Error',
                        body: 'No tienes permiso para realizar esta <br> acción.',
                        variant: 'danger',
                        open: true,
                        life: 3000
                    }, { root: true})
                    this.$store.commit('setNotification', true, { root: true})

                    return 
                } 


                await this.$store.dispatch('pricing_module/viewPricing', { id_pricing: data.id_pricing})

                console.log('pricing:',this.getPricing)
                this.detail_table_data = this.getPricing.product_list
                this.detail_table_data = this.detail_table_data.map( product => {
                    return {
                        quantity: product.quantity,
                        product_name: product.product_name,
                        discount: product.discount,
                        price: product.price,
                        total: product.total
                    }
                })
                this.iva = this.getPricing.iva
                this.subtotal = this.getPricing.subtotal
                this.total = this.getPricing.total

                this.details_modal = true
            },
            selectPricing(data) {
                this.id_pricing = data.id_pricing
                this.sale_modal = true
            },
            processPricing(data) {
                console.log(data)
                
                this.$store.commit('pricing_module/setProcessPricing', { status: true, id_pricing: this.id_pricing})

                this.$store.commit('setAlert', {
                    open: true, 
                    message: 'Cotización cargada en patalla de ventas correctamente',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});

                this.sale_modal = false
            },


            //Paginator
            async nextPage() {
                this.current_page++

                await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: this.current_page, rows: this.rows})
                this.pricing_table_data = this.getPricings
                this.composeTableData();
            },
            async prevPage () {
                if(this.current_page == 1) return

                this.current_page--

                await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: this.current_page, rows: this.rows})
                this.pricing_table_data = this.getPricings
                this.composeTableData();
            },
            async updateRows() {
                await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: this.current_page, rows: this.rows})
                this.pricing_table_data = this.getPricings
                this.composeTableData();
            },
            async goToPage(){
                await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: this.current_page, rows: this.rows})
                this.pricing_table_data = this.getPricings
                this.composeTableData();            
            },

        },
        async created() {
            try {
                //Access
                this.actions = this.setAccess(this.getAccess, 'Ventas', 'Caja', 'Cotizaciones');


                //list of pricings
                await this.$store.dispatch('pricing_module/listPricings', {datatable: true, page: 1, rows: 10})
                this.pricing_table_data = this.getPricings
                this.composeTableData();


                this.fetch_error = false
                this.loading = false
            }
            catch(error) {
                console.log(error)
                this.fetch_error = true
                this.loading = false
            }
        }
    }
</script>