<template>
    <div id="Boxdisplay" class="container-fluid bg-white shadow-sm px-3 mb-5 rounded border-box">
        <div class="row p-0 m-0">

            <div class="col-12 py-2 px-0 bg-lightgray my-3">
                <div class="row p-0 m-0">
                    <div class="col-12 col-xl-6 p-0 pt-2" >
                        <div class="row p-0 m-0 align-items-center" 
                            style="font-size: 12px; max-width: 90%;">

                            <div class="col-6 col-xxl-6 p-0 pl-lg-2 pl-xl-2">
                                <ki-input @keyup.enter="setProduct"
                                    label="Código" 
                                    v-model="product_code"
                                    class="opensans-bold"
                                    placeholder="Buscar producto"
                                    variant="warning"
                                    full/>
                                    <span class="text-danger" v-if="errors.code">
                                        {{ errors.code}}
                                    </span>
                            </div>
                            
                            <div class="col-6 col-xxl-6 p-0 pl-lg-2 pl-xl-2">
                                <ki-input
                                    label="Producto:"
                                    v-model="product_name"
                                    class="opensans-bold"
                                    :disabled="true"
                                    type="text"
                                    full></ki-input>
                            </div>

                            <div class="col-3 col-xxl-3 p-0 pl-2 pt-2">
                                <ki-input label="Existencias:"
                                    v-model="product_in_stock"
                                    :disabled="true"
                                    class="opensans-bold"
                                    placeholder="00:00" 
                                    full/>
                                <span class="text-danger" v-if="errors.stock">
                                    {{ errors.stock }}
                                </span>
                            </div>

                            <div class="col-3 col-xxl-3 p-0 pl-2 pt-2">
                                <ki-input label="Cantidad:"
                                    v-model="product_quantity"
                                    :disabled="true"
                                    class="opensans-bold"
                                    placeholder="00:00" 
                                    full/>
                                <span class="text-danger" v-if="errors.quantity">
                                    {{ errors.quantity }}
                                </span>
                            </div>

                            <div class="col-3 col-xxl-3 p-0 mt-2" style="max-width: 140px">
                                <div class="row">
                                    <div class="col-12 p-0 d-flex pl-3 opensans-bold">
                                        <ki-checkbox label="cotización" v-model="is_pricing" fill/>
                                    </div>
                                    <div class="col-12">
                                        <button @click.stop="removeProductQuantity"
                                            class="btn bg-white border p-0 mr-2"
                                            style="width: 35px;height: 35px"
                                            :disabled="product_quantity <= 0"
                                            type="button">
                                            <img :src="less_icon" />
                                        </button>
                                        <button @click="addProductQuantity"
                                            class="btn p-0"
                                            style="background:#044281; width: 35px; height: 35px"
                                            :disabled="product_in_stock <= 0 && !is_pricing"
                                            type="button">
                                            <img :src="add_icon"
                                                style="background-image: contain" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3 col-xxl-3 p-0 mt-2">
                                <button @click.stop="accessSearch"
                                    class="btn btn-white pills d-flex"
                                    type="button">
                                    <b-icon icon="search"
                                        class="mr-1"
                                        variant="primary">
                                    </b-icon>
                                    Buscar
                                </button>
                            </div>

                            
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 px-0 pt-2" >
                        <div class="row align-items-end pr-2">
                            <div class="col-6 col-xxl-3 p-0 pl-lg-2 pl-xl-1">
                                <ki-input label="Precio sugerido:"
                                    class="opensans-bold"
                                    v-model="suggested_price"
                                    type="number"
                                    placeholder="00:00"
                                    :disabled="true"
                                    full/>
                            </div>
                            <div class="col-6 col-xxl-3 p-0 pl-lg-2 pl-xl-1">
                                <ki-select @change="setPrice"
                                    class="opensans-bold"
                                    :options="[{id: 1, label: 'Al por menor'},{id: 2, label: 'Al por mayor'},]"
                                    v-model="price_option"
                                    type="number"
                                    :disabled="errors.code || product_code == null"
                                    full/>
                            </div>


                            <div class="col-6 col-xxl-3 p-0 pl-lg-2 pl-xl-1">
                                <ki-input label="Precio:"
                                    class="opensans-bold"
                                    v-model="price"
                                    :disabled="true"
                                    type="number"
                                    placeholder="00:00"
                                    full/>
                            </div>


                            <div class="col-6 col-xxl-3 p-0 pl-1 pr-xl-2 pr-xxl-0">
                                <ki-select label="Almacen" 
                                    class="opensans-bold"
                                    v-model="warehouse"
                                    :disabled="true"
                                    :options="warehouse_options"
                                    full>
                                </ki-select>
                            </div>

                            <div class="col-6 col-xxl-3 p-0 pl-lg-2 pl-xl-1">
                                <ki-select @change="setClient" label="Cliente" 
                                    :options="client_options"
                                    class="opensans-bold"
                                    v-model="client"
                                    full />
                            </div>
                            <div class="col-6 col-xxl-3 p-0 pl-lg-2 pl-xl-1 text-dark">
                                <ki-select label="Descuento:" 
                                    class="opensans-bold"
                                    :options="discounts_options"
                                    v-model="discount"
                                    full />
                                <span class="text-danger" v-if="errors.discount">
                                    {{ errors.discount }} 
                                </span>
                            </div>
                            <div class="col-6 col-xxl-3 py-0 px-0 pl-1 align-self-end">
                                <button @click="addProduct"
                                    class="btn btn-white pills mt-2 mt-xxl-0"
                                    type="button">
                                    Agregar
                                </button>
                            </div>
                            <div class="col-6 col-xxl-3 py-0 px-0 pl-1 align-self-end">
                                <button @click="empty_price_modal = true; product_damage = true"
                                    class="btn btn-white pills"
                                    type="button">
                                    Desperfecto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 p-0">
                <p class="opensans-bold"
                    style="color:#044281;">
                    Productos en esta venta
                </p>
            </div>

            <div class="col-12 p-0 mb-3" v-if="product_table_data.length > 0">
                <ki-table :data="product_table_data"
                    :allow_headers="product_table_headers"
                    no_pagination color_headers="success">
                    <template v-slot:Borrar="data">
                        <button @click="selectDeleteProduct(data)"
                            class="btn btn-transparent p-0"
                            type="buton">
                            <img  :src="delete_icon" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <div class="col-12 py-2">
                <div class="row justify-content-end opensans-bold align-items-end" style="font-size: 18px;">
                    <div class="col-12 col-xl-2 py-1 p-xl-0 d-flex justify-content-end">
                        <button @click.stop="pricing_modal = true"
                            class="btn btn-primary pills text-white mr-xl-2"
                            :disabled="product_table_data.length <= 0 || !is_pricing"
                            type="button">
                            Realizar Cotizacion
                        </button>
                    </div>
                    <div class="col-2 p-0">
                        <ki-input label="Bolsas:"
                            v-model="bags"
                            placeholder="00.00"
                            type="number"
                            full/>
                    </div>
                    <div class="col-2 p-0 pl-1">
                        <ki-input label="IVA:"
                            type="number"
                            placeholder="00.00"
                            v-model="price_with_iva"
                            :disabled="true"
                            full/>
                    </div>
                    <div class="col-2 p-0 pl-1">
                        <ki-input label="IGV:"
                            placeholder="00.00"
                            v-model="igv"
                            type="number"
                            :disabled="true"
                            full/>
                    </div>
                    <div class="col-2 p-0 pl-1 ">
                        <ki-input label="%promo:"
                            v-model="price_with_discount"
                            placeholder="00.00"
                            type="number"
                            :disabled="true"
                            full/>
                    </div>
                    <div class="col-2 p-0 pl-1">
                        <ki-input label="Subtotal:"
                            v-model="subtotal"
                            :variant="'success'"
                            placeholder="00.00"
                            type="number"
                            :disabled="true"
                            full/>
                    </div>
                </div>
            </div>
        </div>

        <!--white footer---->
        <div class="position-fixed bg-white  pt-2 all_transitions" 
            style="min-height: 90px; bottom: 0px;"
            :style="`left: ${ show_label ? '250px' : '90px'}; width: ${ show_label ? '84%' : '94%'}`">

            <div class="row align-items-center">
                <div class="col-7 py-4">
                    <div class="row p-0">
                        <div class="col-6 col-xxl-3">
                            <button
                                @click="show_modal = true"
                                class="btn btn-white pills w-100 h-100"
                                style="height: 40px;"
                                :style="`transform: scale(${ show_label ? '0.9' : '1'});`"
                                id="hty-btn"
                                type="button">
                                <img :src="historial_icon"/>
                                Historial de Ventas
                            </button>
                        </div>

                        <div class="col-6 col-xxl px-1 mt-2 mt-xxl-0"
                            style="min-width: 200px">
                            <button
                                class="btn btn-white pills w-100"
                                :style="`transform: scale(${ show_label ? '0.9' : '1'});`"
                                id="withdraw-btn"
                                type="button">
                                <img :src="cash_icon"/>
                                retiro de efectivo
                            </button>
                        </div>

                    </div>
                </div>

                <div class="col-5 p-0">
                    <div class="row p-0">
                        <div class="col-xxl-6">
                            <div class="row p-0">
                                <div class="col-12 opensans-bold p-0">
                                    <p style="font-size: 16px; color: #044281; margin:0;">
                                        Total en esta venta:
                                    </p>
                                </div>
                                <div class="col-12 p-0">
                                    <p  class="poppins-bold"
                                        style="color: #044281; font-size: clamp(24px, 2vw,35px);">
                                        <sup style="font-size: clamp(14px, 1.5vw,28px);">
                                            MXN $
                                        </sup> 
                                        {{ subtotal }}
                                    </p>
                                    </div>
                                </div>
                            </div>
                        <div class="col-xxl-6 p-2">
                            <button @click="accessSale"
                                class="btn text-white pills p-0"
                                id="sales_button"
                                :disabled="product_table_data.length <= 0 || is_pricing"
                                style="background: #198754; height: 60px; font-size: clamp(14px, 2vw, 28px);"
                                type="button">
                                    Procesar venta
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!---Historial-->
        <ki-modal v-if="show_modal">
            <div class="bg-white position-relative">
                <div class="row">
                    <button
                            @click="show_modal = false"
                            class="btn btn-white p-0 position-absolute"
                            style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                            type="button">
                            X
                    </button>
                    <div class="col-12 text-dark">
                        <p class="m-0 opensans py-3">
                            Historial de ventas
                        </p>
                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div  class="col-12">
                                <ki-table :data="historial_table_data"
                                    :allow_headers="historial_table_headers"
                                    no_pagination color_headers="white" color_body="white">
                                    <template v-slot:client_name="data">
                                        {{ data.item.client_name ? data.item.client_name : "Cliente mostrador" }}
                                    </template>
                                    <template v-slot:subtotal="data">
                                        $ {{ formatAmount(data.item.subtotal, 2) }}
                                    </template>
                                    <template v-slot:total="data">
                                        $ {{ formatAmount(data.item.total, 2) }}
                                    </template>
                                    <template v-slot:iva="data">
                                        $ {{ formatAmount(data.item.iva, 2) }}
                                    </template>
                                </ki-table>
                            </div>
                            <div  class="col-12 text-dark d-flex justify-content-end p-3">
                                <ki-input label="Total"
                                    v-model="sales_total"
                                    placeholder="00:00"
                                    type="number">

                                </ki-input>
                            </div>
                            <div class="col-12 d-flex justify-content-end align-items-center pb-3">

                                <button @click="show_modal = false"
                                    class="btn btn-primary pills text-white"
                                    style="width: 150px;"
                                    type="button">
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </ki-modal>

        <!-- empty product list --->
        <ki-modal v-if="dump_modal">
            <div class="row bg-white p-3 text-dark position-relative" style="border-radius: 10px">
                <button @click="dump_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); width: 30px; height: 30px"
                    type="button">
                    X
                </button>
                <div class="col-12 text-center">
                    <p>
                        ¿Esta seguro de vaciar la lista? Esta acción no puede deshacerse
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click="dump_modal = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="close"
                        class="btn btn-danger pills text-white ml-2"
                        type="button">
                        Limpiar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--Search-->
        <ki-modal v-if="search_modal">
            <div class="row bg-white position-relative text-dark p-3 align-items-end" style="border-radius: 15px; min-width: 600px;">
                <button @click.stop="search_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 35px; height: 35px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.5)"
                    type="button">
                    X
                </button>
                <div class="col-8 opensans-bold">
                    <ki-input @keyup.enter="searchProduct" label="Buscar:"
                        type="text" 
                        v-model="search_query"
                        full>
                    </ki-input>
                </div>
                <div class="col-4">
                    <button @click.stop="searchProduct"
                        class="btn btn-primary pills d-flex text-white opensans-bold"
                        type="button">
                        <b-icon icon="search"
                            variant="white"
                            class="mr-1">
                        </b-icon>
                        Buscar
                    </button>
                </div>
                <div class="col-12 py-2">
                    <p>
                        Resultados de busqueda
                    </p>
                </div>

                <div class="col-12 text-dark" style="height: 400px; overflow-y: scroll;">
                    <div class="row">
                        <div class="col-12" >
                            <ki-table :data="search_result_products" v-if="!empty_result && search_result_products.length > 0"
                                :allow_headers="search_result_products_headers"
                                no_pagination color_body="white" color_headers="white">
                                    <template v-slot:sel="data">
                                        <button @click.stop="updateProduct(data.item.upc)"
                                            class="btn btn-primary text-white pills p-0"
                                            style="font-size: 24px; width: 65px;"
                                            type="button">
                                            +
                                        </button>
                                    </template>
                            </ki-table>

                            <p v-else-if="empty_result" class="text-dark opensans-bold text-center">
                                No se encontraron productos con esa descripción.
                            </p>
                        </div>
                    </div>  
                </div>
            </div>
        </ki-modal>

        <!---- Sales --->
        <ki-modal v-if="sale_modal">
            <div class="row bg-white p-3 text-dark position-relative" style="border-radius: 15px;">
                    <button @click.stop="sale_modal = false; sale_transaction_types = []; to_pay = 0"
                        class="btn btn-white p-0 position-absolute"
                        style="width: 35px; height: 35px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0,0.3)"
                        type="button">
                        X
                    </button>
                

                <div class="col-12 opensans-bold">
                    <div class="row">
                        <div class="col-4">
                            <p>
                                Resumen de la venta
                            </p>
                        </div>

                        <div class="col-4">
                            <ki-checkbox @change="setLockbox" 
                                v-model="lockbox" 
                                :disabled="id_pricing"
                                label="Apartado" fill/>
                        </div>

                        <div class="col-4" v-if="!lockbox">
                            <p class="d-flex justify-content-between">
                                total a pagar:
                                <span class="opensans-bold" style="font-size: 20px;">
                                    ${{  subtotal  }} 
                                </span>
                            </p>
                        </div>

                        <div class="col-4" v-if="lockbox">
                            <p class="d-flex justify-content-between">
                                minimo a pagar:
                                <span class="opensans-bold" style="font-size: 20px;">
                                    ${{  min_payment  }} 
                                </span>
                            </p>
                        </div>
                    </div>
                    <hr>
                </div>

                <div class="col-12" v-if="lockbox">
                    <div class="row px-0">

                        <div class="col-6 px-0">
                            <ki-select label="Cliente:"
                                :options="client_options"
                                v-model="client"
                                type="number"
                                class="opensans-bold"
                                full>
                            </ki-select>
                        </div>

                    </div>
                </div>
                
                <div class="col-12">
                    <ki-select label="Seleccionar forma de pago:" @change=" setPaymethod(transaction_type)"
                        class="opensans-bold"
                        :options="transaction_options"
                        v-model="transaction_type"
                        full />
                    <span class="text-danger" v-if="errors.transaction_type">
                        {{ errors.transaction_type }}
                    </span>         
                    <hr>        
                </div>

                <div class="col-12 py-2" v-for="(transaction, index) in sale_transaction_types" :key="index">
                    <div v-if="transaction.type === 'efectivo'" class="position-relative row">

                        <button @click="removeTransactionType(index)"
                            class="btn btn-danger text-white p-0 position-absolute"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <hr>
                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.label }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="updateRest"
                                class="opensans-bold"
                                label="Cantidad a pagar:"
                                type="number"
                                v-model="transaction.amount"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @change="(transaction.change = Number(transaction.quantity_recibed - transaction.amount).toFixed(2))"
                                @keyup.enter="(transaction.change = Number(transaction.quantity_recibed - transaction.amount).toFixed(2))"
                                label="Cantidad recibida:"
                                class="opensans-bold"
                                type="number"
                                v-model="transaction.quantity_recibed"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Su cambio:"
                                type="number"
                                class="opensans-bold"
                                v-model="transaction.change"
                                :disabled="true"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                    </div>

                    <div class="opensans-bold row position-relative" v-if="transaction.type === 'debito' || transaction.type === 'credito'" >
                        <hr>
                        <button @click.stop="removeTransactionType(index)"
                            class="btn btn-danger text-white position-absolute p-0"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.label }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="updateRest"
                                label="Cantidad a pagar:"
                                class="pb-3"
                                placeholder="ej: 2330.00"
                                v-model="transaction.amount"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Últimos cuatro números de tarjeta:"
                                class="pb-3"
                                placeholder="ej: 0945"
                                v-model="transaction.cards_numbers"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>

                        <div class="col-4">
                            <ki-input type="password" class="d-none"></ki-input>
                            <ki-input label="Código de autorización"
                                v-model="transaction.authorization_code"
                                type="password"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12 opensans-bold">
                    <label>
                        restante:
                        <ki-input
                            type="number"
                            :disabled="true"
                            v-model="to_pay">
                        </ki-input>
                    </label>
                </div>

                <div class="col-12 d-flex justify-content-end pt-3">
                        <button  @click.stop="sale_modal = false; sale_transaction_types = []"
                            class="btn btn-white pills"
                            type="button">
                            Cancelar
                        </button>
                        <button @click.stop="processSale"
                            class="btn btn-primary text-white pills ml-2"
                            :disabled="sale_transaction_types.length == 0 || to_pay != 0"
                            type="button">
                            Completar venta
                        </button>
                    </div>
            </div>
        </ki-modal>

        <ki-modal v-if="close_modal">

            <div class="row bg-white p-3 text-dark position-relative" style="border-radius: 10px; width: 600px">
                <button @click="close"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); width: 30px; height: 30px"
                    type="button">
                    X
                </button>

                <div class="col-12" v-if="process_loader">
                    <ki-loader></ki-loader>
                </div>

                <div class="row" v-if="!process_loader">

                    <div class="col-12 text-center">
                        <p class="opensans-bold text-uppercase" style="color: #0B0" v-if="!lockbox">
                            Compra Realizada con exito
                        </p>
                        <p class="opensans-bold text-uppercase" style="color: #0B0" v-if="lockbox">
                            Apartado realizado con exito
                        </p>
                    </div>

                    
                    <div class="col-12 text-start d-flex bg-lightgray pt-3 justify-content-between" style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
                        <p class="opensans-bold"> 
                            Cliente: 
                        </p>
                        <span class="ml-2">
                            {{ client ? client_options[client -1 ].label : 'venta de mostrador'}}
                        </span>
                    </div>

                    <div class="col-12 d-flex justify-content-between text-start bg-lightgray">
                        <p class="opensans-bold">
                            Descuento: 
                        </p>
                        <span class="opensans-bold" style="font-size: 19px;" >
                                {{ price_with_discount }}
                        </span>
                    </div>

                    <div class="col-12 d-flex  justify-content-between text-start bg-lightgray">
                        <p class="opensans-bold">
                            Total de Impuestos(IVA): 
                        </p>

                        <span class="opensans-bold" style="font-size: 19px;">
                                {{ price_with_iva }}
                        </span>
                    </div>

                    <div class="col-12 d-flex  justify-content-between text-start bg-lightgray" v-if="lockbox">
                        <p class="opensans-bold">
                            Cantidad minima a pagar: 
                        </p>

                        <span class="opensans-bold" style="font-size: 19px;">
                                {{ min_payment }}
                        </span>
                    </div>

                    <div class="col-12 d-flex justify-content-between text-start bg-lightgray" style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;" v-if="cash_recived">
                        <p class="opensans-bold">
                            Cantidad recibida: 
                        </p>

                        <span class="opensans-bold" style="font-size: 19px;">
                            {{ cash_recived }}
                        </span>
                    </div>

                    <div class="col-12 d-flex justify-content-between text-start bg-lightgray" style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                        <p class="opensans-bold">
                            Total: 
                        </p>

                        <span class="opensans-bold" style="font-size: 19px;" v-if="!lockbox">
                            - {{ subtotal }}
                        </span>

                        <span class="opensans-bold" style="font-size: 19px;" v-if="lockbox">
                             {{ subtotal }}
                        </span>
                    </div>

                    <div class="col-12 d-flex justify-content-between text-start bg-lightgray" style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                        <p class="opensans-bold">
                            Su cambio: 
                        </p>

                        <span class="opensans-bold" style="font-size: 19px;">
                            {{ change }}
                        </span>
                    </div>

                    <div class="col-12 text-center opensans-bold">
                        <p style="font-size: 20px" v-if="!lockbox"
                            class="m-0">
                            Total en esta compra:
                        </p>
                        <p style="font-size: 20px" v-if="lockbox"
                            class="m-0">
                            Total en este apartado:
                        </p>
                        <span class="opensans-bold text-primary"
                            style="font-size: 32px">
                            ${{ subtotal }}
                        </span>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <button @click="close"
                            class="btn btn-primary pills text-white ml-2"
                            type="button">
                            Cerrar
                        </button>
                    </div>

                </div>
            </div>
        </ki-modal>

        <!----- empty price------>
        <ki-modal v-if="empty_price_modal">
            <div class="row bg-white position-relative text-dark" style="border-radius: 15px; max-width: 400px;">
                <button @click="empty_price_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); width: 30px; height: 30px"
                    type="button">
                    X
                </button>
                <div class="col-12 pt-3 opensans-bold">
                    <p>
                        El precio actual de este producto es de: {{ suggested_price }}
                    </p>
                </div>

                <div class="col-12 opensans-bold">
                    <ki-input label="Asignar precio:"
                        v-model="suggested_price"
                        full>
                    </ki-input>
                </div>
               
                <div class="col-12 py-3 d-flex justify-content-end">
                    <button @click.stop="empty_price_modal = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="() => { addProduct(); empty_price_modal = false}"
                        class="btn btn-primary text-white pills ml-2"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!---cashbox --->
        <div v-if="box_modal" class="position-fixed d-flex" style="top:0px; bottom: 0; left: 0px; right: 0px; background: rgba(0,0,0, 0.5);">
            <form class="row bg-white text-dark p-3" style="border-radius: 10px; height: 300px; margin: auto auto" autocomplete="off">
                <div class="col-12 m-0">
                    <p class="opensans-bold m-0">
                        Abrir Caja
                    </p>
                </div>

                <div class="col-12 text-dark opensans-bold">
                    <ki-input label="Nombre de usuario:"
                        type="text"
                        class="text-dark"
                        :diabled="true"
                        v-model="box_user_name"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="box_user_name_error">
                        {{ box_user_name_error }}
                    </span>
                </div>

                <div class="col-12 opensans-bold">
                    <ki-input label="Contraseña:"
                        type="password"
                        v-model="box_password"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="box_password_error">
                        {{ box_password_error }}
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="openBox"
                        class="btn btn-primary text-white pills"
                        style="width: 100px; height: 40px;"
                        type="button">
                        Enviar
                    </button>
                </div>
            </form>
        </div>

        <!-- pricing-->
        <ki-modal v-if="pricing_modal">
            <div class="row bg-white text-dark p-3 position-relative" style="border-radius: 15px;">
                <button @click.stop="pricing_modal = false"
                    class="btn btn-white position-absolute p-0"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>

                <div class="col-12">
                    <p class="opensans-bold">
                        Completar cotización:
                    </p>
                    <hr>
                </div>

                <div class="col-12 py-3" v-if="send">
                    <div class="row opensans-bold align-items-end">
                        <div class="col-9">
                            <ki-input @keyup.enter="sendPricing"
                                label="Correo eléctronico:"
                                placeholder="Ej: example@mail.com"
                                type="email"
                                v-model="email"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="errors.email">
                                {{  errors.email }}
                            </span>
                        </div>
                        <div class="col-3">
                            <button @click.stop="sendPricing"
                                class="btn btn-primary pills text-white"
                                type="button">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 d-flex justify-content-between">
                    <button @click.stop="princing_modal = false"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="send = true"
                        class="btn btn-primary pills text-white"
                        type="button">
                        Enviar por correo
                    </button>
                    <button @click.stop="printPricing"
                        class="btn btn-primary pills text-white"
                        type="button">
                        imprimir
                    </button>
                </div>

            </div>
        </ki-modal>
    </div>
</template>

<script>
    //icons
    import delete_icon from 'ASSETS/icons/trash-can.svg'
    import historial_icon from 'ASSETS/icons/historial.svg'
    import warning_icon from 'ASSETS/icons/warning.svg'
    import cash_icon from 'ASSETS/icons/cash.svg'
    import add_icon from 'ASSETS/icons/add.svg'
    import less_icon from 'ASSETS/icons/resta.svg'

    //components
    import PillsTabs from '../../../components/PillsTabs.vue'

    import { mapState, mapGetters } from 'vuex'


    export default {
        name: 'ki-boxdisplay',
        components: {
            PillsTabs,
        },
        data() {
            return {
                delete_icon,
                historial_icon,
                warning_icon,
                cash_icon,
                add_icon,
                less_icon,
                product_table_headers: [
                    { key: 'quantity', label: "Cantidad" }, 
                    { key: 'code', label: "Código/Externo"  }, 
                    { key: 'product', label: "Producto"  }, 
                    { key: 'price', label: "Precio"}, 
                    { key: 'discount', label: "Descuento" }, 
                    { key: 'total', label: "Total" },
                    { key: 'Borrar', label: 'Borrar'}
                ],
                product_table_data: [],
                historial_table_data: [],
                historial_table_headers: [
                    { key: 'id_sale', label: '#'},
                    { key: 'store_name', label: 'Sucursal'},
                    { key: 'client_name', label: 'Cliente'},
                    { key: 'subtotal', label: 'Subtotal'},
                    { key: 'iva', label: 'IVA'},
                    { key: 'total', label: 'Total'}
                ],
                client_options: [],
                elements_tab: ['Última venta', 'Ventas anteriores'],
                warehouse_options: [],
                discounts_options: [],
                transaction_options: [],
                product_code: null,
                product_quantity: null,
                product_in_stock: null,
                price: null,
                suggested_price: null,
                product_name: null,
                discount: null,
                amount: 0,
                warehouse: '1',
                dump_modal: false,
                client: null,
                element_active: 'Última venta',
                products: null,
                show_modal: false,
                bags: null,
                price_with_iva: null,
                igv: null,
                price_with_discount: 0,
                subtotal: 0,
                product: null,
                final_total: 0,
                process_loader: false,
                delete_modal: false,
                close_modal: false,
                price_option: 1,
                search_query: null, 
                errors: {
                    code: null,
                    quantity: null,
                    price: null,
                    client: null,
                    discount: null,
                    transaction_type: null,
                    email: null
                },
                transaction_type: null,
                box_user_name: null,
                box_password: null,
                box_user_name_error: null,
                box_password_error: null,
                box_modal: false,
                client_discount: null,
                client_save: null,
                raw_subtotal: null,
                cards_numbers: null,
                authorization_code: null,
                show_card_fields: false,
                search_result_products: [],
                search_result_products_headers: [
                    {key: 'product_name', label: 'Producto' },
                    {key: 'retail_price', label: 'Precio menudeo'},
                    {key: 'sell_price', label: 'Precio mayoreo'},
                    {key: 'vendor_name', label: 'Proveedor'},
                    {key: 'sel'}
                ],
                search_modal: false,
                empty_price_modal: false,
                sale_modal: false,
                sale_transaction_types: [],
                sales_total: 0,
                to_pay: null,
                change: null,
                pricing_modal: false,
                email: null,
                send: false,
                is_pricing: false,
                product_damage: false,
                cash_recived: null,
                empty_result: false,
                id_pricing: null,
                lockbox: null,
                min_payment: null,
                init_date: this.dateParser(Date.now()),
                end_date: this.dateParser(Date.now()),
                actions: {}
            }
        },
        methods: {
            //utilities
            isEmail (value) {
                let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

                if(exp_reg.test(value)) {
                    return true
                }
                else {
                    return false
                }
            },
            async salesHistory() {
                await this.$store.dispatch('reports/listSalesHistory', { datatable: false, date_init: this.init_date, date_end: this.end_date })
                this.historial_table_data = this.sales
                this.sales_total = this.sales.reduce( (acc, sale) => {
                    acc+= sale.total;
                    return acc;
                },0)
            },
            addProductQuantity() {

                if(this.is_pricing) {
                    this.product_quantity++
                }

                else {
                    this.product_in_stock--
                    this.product_quantity++
                }
            },
            removeProductQuantity() {
                if(this.is_pricing) {
                    this.product_quantity--
                }

                else {
                    this.product_in_stock++
                    this.product_quantity--
                }
            },
            async setProduct() {

                this.errors['code'] = null

                console.log(this.product_code)
                
                await this.$store.dispatch('box_operations_module/searchProduct', {type: 'upc', upc: this.product_code})

                if(this.search_result.not_found) {
                    this.errors.code = this.search_result.not_found
                } 
                
                else {

                    this.product_name = this.search_result.product_name
                    this.product_in_stock = this.search_result.quantity
                    this.discount = this.search_result.id_discount
                    
                    let discount_object = this.discounts_options.find( discount => discount.select_id == this.discount)
                    
                    this.discount = discount_object ? discount_object.id : 0

                    if(this.price_option == 1) {
                        this.suggested_price = this.search_result.retail_price
                    }

                    else {
                        this.suggested_price = this.search_result.sell_price
                    }
                    
                }
                
            },
            setPrice() { 
                let product = this.products.filter( product => product.upc === this.product_code)

                if(this.price_option == 1) {
                    this.suggested_price = product[0].retail_price
                }

                else {
                    this.suggested_price = product[0].sell_price
                } 
            },
            addProduct() {

                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(!this.product_code) {
                    this.errors.code = '*Producto inexistente'
                    return
                }

                if(this.product_quantity <= 0) {
                    this.errors.code = '*Ingrese Cantidad'
                    return
                }

                let product_price = this.price == null ? this.suggested_price : this.price

                if(product_price <= 0) {
                    this.empty_price_modal = true
                    this.price_option = 3
                    return
                }

                if(this.product_damage) {
                    this.price_option = 3
                }

                let discounted = 0
                let total_price = 0 

                if(this.discount > 0) {
                     
                    let price_discount = this.formatAmount((product_price * this.discount) / 100)
                    let raw_total = this.formatAmount(product_price * Number(this.product_quantity), 2)

                    discounted = this.formatAmount(Number(price_discount) * Number(this.product_quantity), 2)

                    total_price = this.formatAmount(Number(raw_total) - Number(discounted), 2)

                }

                else {
                    
                    total_price = this.formatAmount(Number(this.product_quantity) * Number(product_price), 2)
                }

                let id_discount = this.discounts_options.filter(discount => discount.id == this.discount)

                let price_type

                this.price_option = parseInt(this.price_option)
                switch (this.price_option) {
                    case 1:
                        price_type = 'retail_price';
                        break;
                    case 2: 
                        price_type = 'sell_price';
                        break;
                    case 3:
                        price_type = 'personalized';
                        break;
                    default:
                        console.log('error no valid price type');
                        break;
                }
                
                let table_item = {
                    quantity: this.product_quantity ,
                    code: this.product_code,
                    product: this.search_result.product_name,
                    id_product: this.search_result.id_product,
                    id_discount: id_discount[0].select_id,
                    price: product_price,
                    discount: discounted,
                    discount_pct: Number(this.discount),
                    amount: this.formatAmount(Number(total_price / 116) * Number(16), 2),
                    //amount: this.formatAmount(this.formatAmount(total_price / 116) * 16, 2),
                    total: Number(total_price).toFixed(2) ,
                    price_type: price_type
                }

                if(price_type == 'personalized') {
                    table_item.custom_price = this.formatAmount(product_price, 2)
                }

                console.log('Price type:',price_type)
                console.log('price option:', this.price_option)

                this.price_option = 1
                this.product_table_data.push(table_item)


                //subtotal calculation
                this.subtotal = this.product_table_data.reduce((acc, item) => {
                        acc = this.formatAmount(Number(acc) + Number(item.total), 2)
                        return acc
                }, 0)

                //discount calculation
                this.price_with_discount = this.product_table_data.reduce( (acc, item) => {
                    acc = Number(acc) + Number(item.discount)
                    return this.formatAmount(acc, 2)
                }, 0)

                //IVA Calculation
                this.price_with_iva = this.product_table_data.reduce( (acc, item) => {
                    acc = Number(acc) + Number(item.amount)
                    return this.formatAmount(acc, 2)
                }, 0)

                //Sale total
                this.final_total = Number(this.subtotal) + Number(this.price_with_iva)
                this.final_total = this.final_total.toFixed(2)

                this.product_code = null
                this.product_name = null
                this.price = null
                this.suggested_price = null
                this.product_quantity = null
                this.product_in_stock = null
                this.product_damage = false
            },
            selectDeleteProduct(data) {

                let index = this.product_table_data.indexOf(data.item)
                delete this.product_table_data[index]
                this.product_table_data = this.product_table_data.filter( item => true)

                this.subtotal = this.product_table_data.reduce((acc, item) => {
                        acc = Number(acc) + Number(item.total)
                        return acc.toFixed(2)
                }, 0)

                this.price_with_discount = this.product_table_data.reduce( (acc, item) => {
                    acc = Number(acc) + Number(item.discount)
                    return acc.toFixed(2)
                }, 0)

                this.price_with_iva = this.product_table_data.reduce( (acc, item) => {
                    acc = Number(acc) + Number(item.amount)
                    return acc.toFixed(2)
                }, 0)

                this.final_total = Number(this.price_with_iva) + Number(this.subtotal)
                this.final_total = this.final_total.toFixed(2)
            },
            close() {
                this.subtotal = 0
                this.price_with_iva = 0
                this.final_total = 0
                this.product_table_data = []
                this.client = null
                this.product_code = null
                this.product_name = null
                this.price = null
                this.close_modal = false
                this.product_quantity = null
                this.discount = 0
                this.suggested_price = null
                this.product_in_stock = null
                this.dump_modal = false
                this.lockbox = false
            },
            setClient() {
                let selected_client = this.client_options.find(client => client.id == this.client)
                this.client_discount  = selected_client.percent
            },
            
    
            //Sale
            accessSale() {
                let access = this.actions.find( action => action.name = 'add')
                
                if(!access.has_permission) {
                    this.$store.commit('setToast', {
                        header: 'Error',
                        body: 'No tienes permiso para realizar esta <br> acción.',
                        variant: 'danger',
                        open: true,
                        life: 3000
                    }, { root: true})
                    this.$store.commit('setNotification', true, { root: true})

                    return 
                } 

                else {
                    this.sale_modal = true
                    this.to_pay = this.subtotal
                }
            },
            async processSale(){
 
                for(let transaction in this.sale_transaction_types) {

                    this.sale_transaction_types[transaction].error = null
                    
                    if(this.sale_transaction_types[transaction].quantity_recibed == null && this.sale_transaction_types[transaction].type == 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*Introduzca la cantidad recibida'
                        return
                    }

                    else if(this.sale_transaction_types[transaction].quantity_recibed <= 0 && this.sale_transaction_types[transaction].type == 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*cantidad no valida'
                        return
                    }

                    if(this.sale_transaction_types[transaction].cards_numbers == null && this.sale_transaction_types[transaction].type !== 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*Introduzca números de tarjeta'
                        return
                    }

                    if(this.sale_transaction_types[transaction].authorization_code == null && this.sale_transaction_types[transaction].type !== 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*Introduzca código de autorización'
                        return
                    }

                    if(this.sale_transaction_types[transaction].amount == null && this.sale_transaction_types[transaction].type !== 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*Introduzca cantidad a pagar'
                        return
                    }

                    if(this.sale_transaction_types[transaction].amount <= 0 && this.sale_transaction_types[transaction].type !== 'efectivo') {
                        this.sale_transaction_types[transaction].error = '*Cantidad no valida'
                        return
                    }
                }

                

                let product_list = this.product_table_data.map( product => {

                    let sale_object = {
                        id_product: product.id_product,
                        id_discount: product.id_discount,
                        discount_pct: product.discount_pct,
                        price_type: product.price_type,
                        quantity: product.quantity
                    }

                    if(product.custom_price) {
                        sale_object.custom_price = this.formatAmount(product.custom_price, 2)
                    }

                    return sale_object
                })

                let payment = this.sale_transaction_types.map( transaction => {
                    if(transaction.type == 'efectivo') {
                        this.change = transaction.change
                        this.cash_recived = transaction.quantity_recibed
                        return {
                            id_transaction_type: transaction.id_transaction,
                            amount: Number(transaction.amount).toFixed(2),
                            money_received: Number(transaction.quantity_recibed).toFixed(2),
                            effective_change: Number(transaction.change).toFixed(2)
                        }
                    }

                    else {
                        return {
                            id_transaction_type: transaction.id_transaction,
                            amount: Number(transaction.amount).toFixed(2),
                            numbers_card: transaction.cards_numbers,
                            code: transaction.authorization_code
                        }
                    }
                })


                const payload =  {
                    id_transaction_type: Number(this.transaction_type),
	                product_list: product_list,
	                discount_manual: 0,
	                subtotal: Number(Number(this.subtotal) - Number(this.price_with_iva)).toFixed(2),
	                iva: Number(this.price_with_iva),
	                total: Number(this.subtotal),
                    payment: payment
                }

                if(this.id_pricing) {
                    payload.id_pricing = this.id_pricing
                }

                if(this.lockbox) {
                    payload.is_reserve = true
                    payload.client_id = Number(this.client)
                }

                else {
                    payload.is_reserve = false
                }

                console.log('Sale:', payload);
                console.log('status:', this.sale_status);

                
                await this.$store.dispatch('box_operations_module/sale', payload)

                if(this.sale_status) {
                    this.sale_modal = false
                    this.transaction_type = null
                    this.cards_numbers = null
                    this.authorization_code = null
                    this.close_modal = true
                    this.process_loader = true
                    this.id_pricing = null
                    this.cash_recived = null
                    this.change = null

                    this.sale_transaction_types = [];

                    this.$store.commit('pricing_module/setProcessPricing', { status: false, id_pricing: null})
                    await this.salesHistory();
                
                    setTimeout(() => {
                        this.process_loader = false
                    }, 3000)
                }
                
            },
            setPaymethod: function (method) {

                if (this.sale_transaction_types.length >= 3) return

                let transaction_type = this.transaction_options.find(transaction => transaction.id == method)

                let efective_option = this.sale_transaction_types.find( transaction => transaction.type == 'efectivo')
                
                if(efective_option  && efective_option.type === transaction_type.type) return


                switch (transaction_type.type) {
                    case 'efectivo':
                        this.sale_transaction_types.push({
                            label: 'EFECTIVO',
                            type: 'efectivo',
                            amount: null,
                            quantity_recibed: null,
                            change: null,
                            error: null,
                            id_transaction: transaction_type.id
                        })
                        break;
                    case 'debito':
                        this.sale_transaction_types.push({
                            label: 'DEBITO',
                            type: 'debito',
                            cards_numbers: null,
                            amount: null,
                            authorization_code: null,
                            error: null,
                            id_transaction: transaction_type.id
                        })
                        break;
                    case 'credito':
                        this.sale_transaction_types.push({
                            label: 'CREDITO',
                            type: 'credito',
                            cards_numbers: null,
                            amount: null,
                            authorization_code: null,
                            error: null,
                            id_transaction: transaction_type.id
                        })
                        break;
                    default:
                        break;
                }

                this.transaction_type = null

                console.log('collection:', this.sale_transaction_types)

            },
            removeTransactionType(index) {
                console.log(index)
                delete this.sale_transaction_types[index]
                this.sale_transaction_types = this.sale_transaction_types.filter( transaction => true)
                this.updateRest()
            },
            updateRest(){
    
                let buyed = this.sale_transaction_types.reduce((acc, item) => {
                    acc =  Number(acc) + Number(item.amount)
                    console.log(acc)
                    return Number(acc).toFixed(2)
                }, 0)
                
                if(this.subtotal - buyed < 0) return

                if(this.lockbox) {
                    this.to_pay = Number(this.min_payment).toFixed(2) - Number(buyed).toFixed(2)
                    this.to_pay = Number(this.to_pay).toFixed(2)
                    this.to_pay = this.to_pay < 0 ? 0 : this.to_pay;
                } else {
                    this.to_pay = Number(this.subtotal).toFixed(2) - Number(buyed).toFixed(2)
                    this.to_pay = Number(this.to_pay).toFixed(2)
                }
                
            },
            
            //search
            accessSearch(){
                let access = this.actions.find( action => action.name = 'search')
                
                if(!access.has_permission) {
                    this.$store.commit('setToast', {
                        header: 'Error',
                        body: 'No tienes permiso para realizar esta <br> acción.',
                        variant: 'danger',
                        open: true,
                        life: 3000
                    }, { root: true})
                    this.$store.commit('setNotification', true, { root: true})

                    return 
                } 

                else {
                    this.search_modal = true
                }
            },
            async searchProduct(){

                if(this.search_query == null) return 

                await this.$store.dispatch('box_operations_module/searchProduct', {type: 'product_name', product_name: this.search_query})

                if(this.search_result.not_found) {
                    this.empty_result = true
                }

                else  {
                    this.search_result_products = this.search_result.map( product => {
                        return {
                            product_name: product.product_name,
                            upc: product.upc,
                            retail_price: product.retail_price,
                            sell_price: product.sell_price,
                            vendor_name: product.vendor_name,
                            sel: '-'
                        }
                    })
                    this.empty_result = false
                }
                
            },
            updateProduct(code) {
                this.setProduct(code)
                this.product_code = code
                this.search_modal = false
                this.search_query = null
            },

            //pricing
            async sendPricing() {

                this.errors['email'] = ''

                if (_.isNull(this.email) || _.isEmpty(this.email)) {
                    this.errors['email'] = '* Añadir correo'
                }

                else if (!this.isEmail(this.email)) {
                    this.errors['email'] = 'Correo no válido'
                    return
                }

                let product_list = this.product_table_data
                

                const payload = {
                    product_list: product_list,
                    total: Number(this.subtotal),
                    iva: Number(this.price_with_iva),
	                subtotal: this.formatAmount(Number(this.subtotal / 116) * Number(100), 2),
                    email: this.email
                }

                console.log('PAYLOAD:', payload)

                await this.$store.dispatch('box_operations_module/pricing', payload)
                this.pricing_modal = false
            },
            printPricing() {
                console.log('printed')
            },

            //box
            async openBox() {

                this.box_password_error = null 
                
                let complete = true

                if(_.isNull(this.box_password) || _.isEmpty(this.box_password)) {
                    this.box_password_error = '*Campo obligatorio'
                    complete = false
                    return
                }

                if(complete) {
                    const payload = {
                        password: this.box_password
                    }
                    await this.$store.dispatch('box_operations_module/openBox', payload )
                    if(this.box_status.open) {
                        this.box_modal = false
                    }
                }
            },

            //lockbox
            setLockbox(){
                if(this.lockbox) {
                    this.min_payment = this.formatAmount(this.subtotal * 0.20, 2);
                    this.updateRest();
                }

                else {
                    this.subtotal = this.product_table_data.reduce((acc, item) => {
                        acc = this.formatAmount(Number(acc) + Number(item.total), 2)
                        return acc
                    }, 0);
                    this.updateRest();
                    
                }
            }
        },
        computed: {
            ...mapState(['show_label']),
            ...mapGetters({
                getProducts: 'product_transfers_module/getWarehouse',
                getWarehouses: 'warehouse/getWarehouses',
                getDiscounts: 'discounts/getDiscounts',
                getTransactions: 'transaction_types/getTransactions',
                session: 'getSession',
                box_status: 'box_operations_module/getStatus',
                clients: 'clients/getClients',
                sale_status: 'box_operations_module/getSale',
                search_result: 'box_operations_module/getSearchResult',
                getProcessPricing: 'pricing_module/getProcessPricing',
                getPricing: 'pricing_module/getPricing',
                getAccess: 'getAccess',
                sales: 'reports/getCollection'
            })
        },
        async created() {

            try {
                //Access
                this.actions = this.setAccess(this.getAccess, 'Ventas', 'Caja', 'Ventas');

                //box open
                this.box_user_name = this.session.username
                
                await this.$store.dispatch('box_operations_module/checkStatus')
                
                if(!this.box_status.open) {
                    this.box_modal = true
                }

                //products to sale
                await this.$store.dispatch('product_transfers_module/listWarehouseProducts', { id_distribution_center:this.session.id_dc})
                this.products = this.getProducts

                this.products = this.products.filter( product => product.quantity > 0)
            
                //distribution center options
                await this.$store.dispatch('warehouse/listWarehouses', {datatable: false})
                this.warehouse_options = this.getWarehouses.map( warehouse => {
                    return {
                        id: warehouse.id_dc,
                        label: warehouse.dc_name
                    }
                })

                this.warehouse = this.session.id_dc

                //discounts options
                await this.$store.dispatch('discounts/listDiscounts', {datatable: false})
                this.discounts_options = this.getDiscounts.map( discount => {
                    return  {
                        id: discount.discount_pct,
                        label: discount.discount_description,
                        select_id: discount.id_discount
                    }
                })

                //transaction types options
                await this.$store.dispatch('transaction_types/listTransactionsTypes', { datatable: false})
                this.transaction_options = this.getTransactions.map( transaction => {
                    return { id: transaction.id_transaction_type, label: transaction.transaction_name.toUpperCase(), type: transaction.transaction_name.toLowerCase()}
                })
                
                //clients options
                await this.$store.dispatch('clients/listClients', { datatable: false})
                this.client_options = this.clients.map( client => {
                    return { id: client.id_client, label: client.client_name, percent: client.client_discount_percent}
                })

                //pricing
                if(this.getProcessPricing.status) {

                    await this.$store.dispatch('pricing_module/viewPricing', { id_pricing: this.getProcessPricing.id_pricing})
                  
                    this.id_pricing = this.getPricing.id_pricing
                    this.product_table_data = this.getPricing.product_list.map( product => {
                        let table_item = {
                            quantity: product.quantity ,
                            code: product.upc,
                            product: product.product_name,
                            id_product: product.id_product,
                            id_discount: product.id_discount,
                            custom_price: product.price,
                            discount: product.discount,
                            discount_pct: product.discount_pct,
                            amount: product.iva,
                            total: product.total,
                            price_type: 'personalized'
                        }

                        return table_item
                    })

                    //subtotal calculation
                    this.subtotal = this.product_table_data.reduce((acc, item) => {
                            acc = this.formatAmount(Number(acc) + Number(item.total), 2)
                            return acc
                    }, 0)

                    //discount calculation
                    this.price_with_discount = this.product_table_data.reduce( (acc, item) => {
                        acc = Number(acc) + Number(item.discount)
                        return this.formatAmount(acc, 2)
                    }, 0)

                    //IVA Calculation
                    this.price_with_iva = this.product_table_data.reduce( (acc, item) => {
                        acc = Number(acc) + Number(item.amount)
                        return this.formatAmount(acc, 2)
                    }, 0)

                }

                //historical sales
                await this.salesHistory();


            }

            catch(error) {
                console.log(error)
            }
            
        },

        beforeDestroy(){
            this.$store.commit('pricing_module/setProcessPricing', { status: false, id_pricing: null});
        }
    }
</script>

<style lang="scss" scoped>
    @mixin upTo($size) {
        @media (max-width: $size) {
            @content;
        }
    }

    #Boxdisplay {
        @include upTo(1026px) {
            #hty-btn {
                transform: scale(0.8);
            }

            #cancel-btn {
                transform: scale(0.8);
            }

            #withdraw-btn {
                transform: scale(0.8);
            }

            #delete-btn {
                transform: scale(0.8);
            } 
            
        }

        .search_row {
            padding: 0px;
            margin: 10px;
        }

        .search_row:hover {
            outline: 1px solid #034381;
            cursor: pointer;
        }

        #sales_button {
            width: 100%;
            @include upTo(1490px) {
                width: 75%
            }
        }
    }
</style>