<template>
    <div id="home" class="container-fluid px-3">
        <titles title="Ventas" :subtitle="tab_active" :othertitle="titletab_active"></titles>
        <tabs :array_tabs="tabs_array" @changeTab="controlTabs($event)"></tabs>
        <title-tabs :array_titletabs="titletabs_array" @changeTitle="controlTitles($event)"></title-tabs>

        <div  v-if="tab_active == 'Caja'">
            <box-display v-if="titletab_active == 'Ventas'"></box-display>
            <lockbox v-if="titletab_active == 'Apartados'"></lockbox>
            <pricing v-if="titletab_active == 'Cotizaciones'"></pricing>
            <ware-changes v-if="titletab_active == 'Cambios de mercancia'"></ware-changes>
            <reprint v-if="titletab_active == 'Reimpresión'"></reprint>
            <box-cut v-if="titletab_active == 'Corte de caja'"></box-cut>
        </div>

        
    </div>
</template>

<script>
    //Components
    import Titles from '../components/Titles.vue'
    import Tabs from '../components/Tabs.vue'
    import TitleTabs from '../components/TitleTabs.vue'

    //Views
    //CashRegister
    import Boxdisplay from "./Sales/CashRegister/Boxdisplay.vue";
    import Warechanges from "./Sales/CashRegister/Warechanges.vue";
    import Reprint from "./Sales/CashRegister/Reprint.vue";
    import Boxcut from "./Sales/CashRegister/Boxcut.vue";
    import Pricing from "./Sales/CashRegister/Pricing.vue";
    import Lockbox from "./Sales/CashRegister/Lockbox.vue";
    


    //Icons
    import icon_cash from '../assets/icons/icon_cash.png'
    import icon_sale from '../assets/icons/icon_sale.png'


    export default {
        name: 'Home',
        components: {
            'titles': Titles,
            'tabs': Tabs,
            'title-tabs': TitleTabs,
            'box-display': Boxdisplay,
            'ware-changes': Warechanges,
            'reprint': Reprint,
            'box-cut': Boxcut,
            'pricing': Pricing,
            'lockbox': Lockbox
        },
        data() {
            return {
                tabs_array:[
                    { img: icon_cash,  name: 'Caja', selected: true, key: 'cashregister' },
                    //{ img: icon_sale,  name: 'Facturación', selected: false, key: 'invoicing' },
                ],
                titletabs_array:[],

                title_cashregister: [
                    { name: 'Ventas', selected: true },
                    { name: 'Cotizaciones', selected: false },
                    { name: 'Apartados', selected: false},
                    { name: 'Cambios de mercancia', selected: false },
                    { name: 'Reimpresión', selected: false },
                    { name: 'Corte de caja', selected: false },
                    //{ name: 'Historial de traspasos', selected: false }
                ],
                title_invoicing: [
                    { name: 'Facturación', selected: true }
                ],
                tab_active: null,
                titletab_active: null
            }
        },
        methods: {
            async controlTabs(event) {
                this.tab_active = event
                for (let i in this.tabs_array) {
                    if(this.tabs_array[i].selected){
                        this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                    }
                }
                for (let i in this.titletabs_array) {
                    if(this.titletabs_array[i].selected){
                        this.titletab_active = this.titletabs_array[i].name
                    }
                }
            },
            async controlTitles(event) {
                this.titletab_active = event
            },
        },
        created(){
            for (let i in this.tabs_array) {
                if(this.tabs_array[i].selected){
                    this.tab_active = this.tabs_array[i].name
                    this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                }
            }
            for (let i in this.titletabs_array) {
                if(this.titletabs_array[i].selected){
                    this.titletab_active = this.titletabs_array[i].name
                }
            }
        }
    }
</script>

<style lang="scss">
</style>