<template>
   <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded" style="min-height: 80vh">

        <div class="col-12 p-0">
            <div class="row bg_gray px-0 py-3 my-2 align-items-end">

                <div class="col-12 pl-2">
                    <ki-input label="Ticket:" @change="searchTicket"
                        type="number"
                        v-model="devolution.ticket"
                        class="opensans-bold">
                    </ki-input>
                </div>


                <div class="col-6 col-lg-3 col-xxl-2 p-0 pl-2 pr-2 pr-lg-0">
                    <ki-select label="Producto:" @change="setProduct"
                        placeholder="Nombre producto"
                        class="opensans-semibold pt-1 pl-1"
                        v-model="devolution.product"
                        :options="devolution.products"
                        :disabled="!devolution.ticket"
                        full>
                    </ki-select>
                    <span class="text-danger" v-if="error_devolution.name">
                        {{ error_devolution.name }}
                    </span>
                </div>


                <div class="col-6 col-lg-2 col-xxl-1 p-0 pl-1 pl-lg-0">
                    <ki-input label="Precio:"
                        placeholder="00:00"
                        class="opensans-semibold pt-1 pl-1"
                        v-model="devolution.product_price"
                        :disabled="true"
                        type="number"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_devolution.price">
                        {{ error_devolution.price }}
                    </span>
                </div>

                <div class="col-6 col-lg-2 col-xxl-1 p-0 pr-2 pr-lg-0">
                    <ki-input label="Cantidad vendida:"
                        placeholder="00:00"
                        v-model="devolution.sale_quantity"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        :disabled="true"
                        full>
                    </ki-input>
                </div>


                <div class="col-6 col-lg-2 col-xxl-1 p-0 pr-2 pr-lg-0">
                    <ki-input label="Cantidad a cambiar:"
                        placeholder="00:00"
                        v-model="devolution.product_quantity"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        :disabled="!devolution.ticket"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_devolution.quantity">
                        {{ error_devolution.quantity }}
                    </span>
                </div>


                <div class="col p-0">
                    <div class="h-100 d-flex justify-content-start align-items-end pl-2 pt-2 pt-lg-0">
                        <button @click.stop="addProduct('devolution')"
                            class="pills bg-white px-4">
                            Agregar
                        </button>
                    </div>
                </div>

                  
            </div>
        </div>

        <div v-if="(devolution.table_data.length > 0)"
            class="col-12 p-0 mt-3" 
            style="border: solid 1px #DCDDE0;" >
            <ki-table :data="devolution.table_data"
                :allow_headers="devolution.table_headers"
                no_pagination color_headers="white" color_body="white">
                <template v-slot:seleccion="data">
                    <button @click.stop="removeItem('devolution', data.item)"
                        class="btn btn-danger p-0"
                        style="width: 25px; height: 25px;"
                        tyoe="button">
                        X
                    </button>
                </template>
            </ki-table>
        </div>

        <div class="col-12 d-flex justify-content-end mt-2">
            <ki-input label="Total:"
                :variant="'danger'"
                class="opensans-bold pt-1 pl-1"
                placeholder="00:00"
                v-model="devolution.total"
                type="number"
                full>
            </ki-input>
        </div>

        <div class="col-12 text-dark opensans-bold p-0" >
            <p>
                Productos entregados a cliente
            </p>
        </div>

        <div class="col-12 p-0">     
            <div class="row bg_gray px-0 py-3 align-items-end">

                <div class="col-6 col-lg-3 col-xxl-1 p-0">
                    <ki-input @input="searchProduct()" label="Código"
                        placeholder="Ingrese código"
                        v-model="client.product_code"
                        class="opensans-semibold  pt-1 pl-1"
                        type="number"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_client.code">
                        {{ error_client.code }}
                    </span>
                </div>

                <div class="col-6 col-lg-3 col-xxl-2 p-0 pr-2 pr-lg-0">
                    <ki-input label="Producto:"
                        placeholder="Nombre producto"
                        class="opensans-semibold pt-1 pl-1"
                        v-model="client.product_name"
                        :disabled="true"
                        type="text"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_client.name">
                        {{ error_client.name }}
                    </span>
                </div>

                <div class="col-2 col-lg-2 col-xxl-1 p-0 pl-1 pl-lg-0">
                    <ki-input label="Precio:"
                        placeholder="00:00"
                        v-model="client.product_price"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_client.price">
                        {{ error_client.price }}
                    </span>
                </div>

                <div class="col-2 col-xxl-3 p-0 pl-lg-2 pl-xl-1">
                    <ki-select @change="setPrice"
                        class="opensans-bold"
                        :options="[{id: 1, label: 'Al por menor'},{id: 2, label: 'Al por mayor'},]"
                        v-model="client.price_option"
                        type="number"
                        :disabled="error_client.code || client.product_code == null"
                        full/>
                </div>

                <div class="col-2 col-lg-2 col-xxl-1 p-0 pr-2 pr-lg-0">
                    <ki-input label="Cantidad"
                        placeholder="Ingrese cantidad"
                        v-model="client.product_quantity"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_client.quantity">
                        {{ error_client.quantity }}
                    </span>
                </div>

                <div class="col p-0  pt-2 pt-xxl-0">
                    <div class="h-100 d-flex justify-content-start align-items-end pl-2">
                        <button @click.stop="addProduct('client')"
                            class="pills bg-white px-4">
                            Agregar
                        </button>
                    </div>
                </div>

            </div>         
        </div>

        <div class="col-12 p-0 mt-3" style="border: solid 1px #DCDDE0;" v-if="(client.product_data.length > 0)">
            <ki-table :data="client.product_data"
                :allow_headers="client.product_headers"
                no_pagination color_headers="white" color_body="white">
                <template v-slot:seleccion="data">
                    <button @click.stop="removeItem('client',data.item)"
                        class="btn btn-danger p-0"
                        style="width: 25px; height: 25px;"
                        tyoe="button">
                        X
                    </button>
                </template>
            </ki-table>
        </div>

        <div class="col-12 d-flex justify-content-end  mt-2">
            <ki-input label="Total"
                placeholder="00:00"
                v-model="client.total"
                :variant="'success'"
                class="opensans-semibold pt-1 pl-1"
                type="number"
                full>
            </ki-input>
        </div>

        <div class="col-12 p-0 mt-3">
            <div class="row p-0 col d-flex justify-content-end align-items-center">
                <div>
                    <button @click.stop="clearFields"
                        class="btn btn-white pills"
                        type="button">
                        Limpiar
                    </button>
                    <button @click.stop="(process_modal = true)"
                        class="pills bg-primary px-4 text-white ml-2"
                        :disabled="(devolution.table_data.length <= 0)">
                        Cambiar
                    </button>
                </div>
            </div>
        </div>

        <!---Modal-->
        <ki-modal v-if="process_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 5px;" >
                <button @click.stop="(process_modal = false)"
                    class="btn btn-white position-absolute p-0"
                    style="width: 30px; height: 30px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                    type="button">
                    X
                </button>

                <div class="col-12 opensans-bold">
                    <p class="py-2" style="font-size: 18px;">
                        Resumen del cambio/devolución
                    </p>
                </div>

                <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0 justify-content-evenly opensans-bold">
                        <div class="col-4">
                            <p>
                                Total devuelto: 
                                <span style="font-size: 18px">
                                    $ {{ devolution.total}}
                                </span>
                            </p>
                        </div>

                        <div class="col-4">
                            <p>
                                Total cambio: 
                                    <span style="font-size: 18px">
                                        $ {{ client.total}}
                                    </span>
                            </p>
                        </div>

                        <div class="col-4">
                            <p>
                                Diferencia: 
                                <span style="font-size: 18px">
                                    $ {{ formatAmount(devolution.total - client.total)}}
                                </span>
                            </p>
                        </div>
                    </div>

                    <hr>
                </div>

                <div class="col-12 opensans-bold">
                    <p style="font-size: 18px;">
                        Método de pago
                    </p>
                </div>

                <div class="col-12">
                    <ki-select label="Seleccionar forma de pago:" @change="setPaymethod(transaction_type)"
                        class="opensans-bold"
                        :options="transaction_options"
                        v-model="transaction_type"
                        full />
                    <span class="text-danger" v-if="errors.transaction_type">
                        {{ errors.transaction_type }}
                    </span>         
                    <hr>        
                </div>

                <div class="col-12 py-2" v-for="(transaction, index) in sale_transaction_types" :key="index">
                    <div v-if="transaction.type === 'efectivo'" class="position-relative row">

                        <button @click="removeTransactionType(index)"
                            class="btn btn-danger text-white p-0 position-absolute"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <hr>
                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.type }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="transaction.amount > pending_payment ?  transaction.amount = pending_payment : transaction.amount"
                                class="opensans-bold"
                                label="Cantidad a pagar:"
                                type="number"
                                v-model="transaction.amount"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @change="(transaction.effective_change = Number(transaction.money_received - transaction.amount).toFixed(2))"
                                @keyup.enter="(transaction.effective_change = Number(transaction.money_received - transaction.amount).toFixed(2))"
                                label="Cantidad recibida:"
                                class="opensans-bold"
                                type="number"
                                v-model="transaction.money_received"
                                full >
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Su cambio:"
                                type="number"
                                class="opensans-bold"
                                v-model="transaction.effective_change"
                                :disabled="true"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                    </div>

                    <div class="opensans-bold row position-relative" v-if="transaction.type === 'debito' || transaction.type === 'credito'" >
                        <hr>
                        <button @click.stop="removeTransactionType(index)"
                            class="btn btn-danger text-white position-absolute p-0"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.type }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="transaction.amount > pending_payment ?  transaction.amount = pending_payment : transaction.amount"
                                label="Cantidad a pagar:"
                                class="pb-3"
                                placeholder="ej: 2330.00"
                                v-model="transaction.amount"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Últimos cuatro números de tarjeta:"
                                class="pb-3"
                                placeholder="ej: 0945"
                                v-model="transaction.numbers_card"
                                type="number"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>

                        <div class="col-4">
                            <ki-input type="password" class="d-none"></ki-input>
                            <ki-input label="Código de autorización"
                                v-model="transaction.code"
                                type="password"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                    </div>
                </div>


                <div class="col-12 py-2 d-flex justify-content-end">
                    <button @click.stop="(process_modal = false)"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="confirmChanges"
                        class="btn btn-primary pills text-white ml-1"
                        type="button">
                        Guardar
                    </button>
                </div>

            </div>
        </ki-modal>
    </div>
</template>

<script>
    //helpers
    import { mapGetters } from 'vuex'


    export default {
        name: 'ki-warechanges',

        data() {
            return {
                //modals
                process_modal: false,

                //devolution

                devolution: {
                    table_headers: [
                        {key: 'quantity', label: 'Cantidad'},
                        {key: 'product_name', label: 'Producto'},
                        {key: 'price', label: 'Precio'},
                        {key: 'total', label: 'Total'},
                        {key: 'code', label: 'Código'},
                        {key: 'seleccion'}
                    ],
                    table_data: [],
                    ticket: null,
                    product: null,
                    product_code: null,
                    product_name: null,
                    product_price:null,
                    product_quantity: null,
                    sale_quantity: null,
                    products: [],
                    total: null,
                },
                
                error_devolution: {
                    code: null,
                    name: null,
                    price: null,
                    quantity: null
                },


                //client products
                client: {
                    product_headers: [
                        {key: 'seleccion'},
                        {key: 'quantity', label: 'Cantidad'},
                        {key: 'product_name', label: 'Producto'},
                        {key: 'price', label: 'Precio'},
                        {key: 'total', label: 'Total'},
                        {key: 'code', label: 'Código'}
                    ],
                    product_data: [],
                    price_option: '1',
                    product: null,
                    product_code: null,
                    product_name: null,
                    product_price: null,
                    product_quantity: null,
                    total: null,
                },
                
                error_client: {
                    code: null,
                    name: null,
                    price: null,
                    quantity: null
                },

                //others
                transaction_options: [],
                sale_transaction_types: [],
                transaction_type: null,
                errors: {
                    transaction_type: null,
                },
                
                difference: null
            };
        },

        methods: {
            formatAmount(value, decimals = 2) {
			    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
		    },
            async searchProduct(type) {
                console.log('type', type)
                
                await this.$store.dispatch('box_operations_module/searchProduct', { type: 'upc', upc: this.client.product_code})
                this.client.product = this.search_result
                //console.log('product:', this.client_product)

                if(this.client.product) {
                    this.client.product_price = this.client.product.retail_price
                    this.client.product_name = this.client.product.product_name
                }

                else {
                    this.client.product_price = null
                    this.client.product_name = null
                }
                
            },
            addProduct(type) {
                let complete;
                switch(type) {
                    case 'devolution':

                        for(let error in this.error_devolution) {{
                            this.error_devolution[error] = null
                        }}

                        complete = true


                        if(_.isNull(this.devolution.product) || _.isEmpty(this.devolution.product)) {
                            complete = false
                            this.error_devolution.name = '*Obligatorio'
                        }

                        if(_.isNull(this.devolution.product_price)) {
                            complete = false
                            this.error_devolution.price = '*Obligatorio'
                        }

                        if(_.isNull(this.devolution.product_quantity)) {
                            complete = false
                            this.error_devolution.quantity = '*Obligatorio'
                            return
                        }

                        else if(this.devolution.product_quantity > this.devolution.sale_quantity) {
                            complete = false
                            this.error_devolution.quantity = '*No puede ser mayor a cantidad vendida';
                            return
                        }

                        if(complete) {

                            let product = this.devolution_item.product_list.filter( product => product.upc == this.devolution.product)
                            
                            let table_product = {
                                seleccion: '- ',
                                code: this.devolution.product,
                                product_name: product[0].product_name,
                                price: this.devolution.product_price,
                                quantity: this.devolution.product_quantity,
                                total: (Number(this.devolution.product_price) * Number(this.devolution.product_quantity)).toFixed(2)
                            }

                            this.devolution.table_data.push(table_product)

                            this.devolution.total = this.devolution.table_data.reduce( (acc, product) => {

                                acc = Number(acc) + Number(product.total)
                                return acc.toFixed(2)

                            }, 0)
                        }
                        return
                    case 'client':

                            for(let error in this.error_client) {{
                                this.error_client[error] = null
                            }}

                            complete = true

                            if(_.isNull(this.client.product_code)) {
                                complete = false
                                this.error_client.code = '*Obligatorio'
                            }

                            if(_.isNull(this.client.product_name) || _.isEmpty(this.client.product_name)) {
                                complete = false
                                this.error_client.name = '*Obligatorio'
                            }

                            if(_.isNull(this.client.product_price)) {
                                complete = false
                                this.error_client.price = '*Obligatorio'
                            }

                            if(_.isNull(this.client.product_quantity)) {
                                complete = false
                                this.error_client.quantity = '*Obligatorio'
                                return
                            }

                            if(complete) {
                                let product = {
                                    seleccion: '- ',
                                    code: this.client.product_code,
                                    product_name: this.client.product_name,
                                    price: this.client.product_price,
                                    quantity: this.client.product_quantity,
                                    total: (Number(this.client.product_price) * Number(this.client.product_quantity)).toFixed(2)
                                }

                                this.client.product_data.push(product)

                                this.client.total = this.client.product_data.reduce( (acc, product) => {

                                    acc = Number(acc) + Number(product.total)
                                    console.log('acc:', acc)
                                    return acc.toFixed(2)

                                }, 0)
                            }
                        return
                }
                
            },
            removeItem(type,item) {
                let index
                switch(type) {
                    case 'devolution':
                        index = this.devolution.table_data.indexOf(item)
                        delete this.devolution.table_data[index]
                        this.devolution.table_data = this.devolution.table_data.filter( item => true)

                        this.devolution.total = this.devolution.table_data.reduce( (acc, product) => {

                            acc = Number(acc) + Number(product.total)
                            return acc.toFixed(2)

                        }, 0)
                        return;
                    case 'client':
                        index = this.client.product_data.indexOf(item)
                        delete this.client.product_data[index]
                        this.client.product_data = this.client.product_data.filter( item => true)

                        this.client.total = this.client.product_data.reduce( (acc, product) => {

                            acc = Number(acc) + Number(product.total)
                            return acc.toFixed(2)

                        }, 0)
                        return
                }
            },
            clearFields() {

                for(let property in this.client) {
                    if(typeof(this.client[property]) == 'object') {
                        continue;
                    }

                    else {
                        this.client[property] = null
                    }
                }

                for(let property in this.devolution) {
                    if(typeof(this.devolution[property]) == 'object'){
                        continue;
                    }

                    else {
                        this.devolution[property] = null
                    }
                }

                this.devolution.table_data = []
                this.devolution.products = []
                this.client.table_data = []
                this.client.product_data = []
                this.client.price_option = '1'
            },
            confirmChanges() {
                console.log('CAMBIOS DE MERCANCIA')
                setTimeout(() => {
                    this.process_modal = false
                }, 3000)
            },
            setPrice() {

                if(this.client.price_option == '1') {
                    this.client.product_price = this.client.product.retail_price
                }

                else {
                    this.client.product_price = this.client.product.sell_price
                }
            },
            async searchTicket() {
                await this.$store.dispatch('changes_module/viewChange', { id_sale: this.devolution.ticket})
                this.devolution.products = this.devolution_item.product_list.map( product => {
                    return {
                        id: product.upc,
                        label: product.product_name
                    }
                })
            },
            setProduct() {
                let product = this.devolution_item.product_list.filter( product => product.upc == this.devolution.product)
                console.log('product:', product)
                this.devolution.sale_quantity = product[0].quantity
                this.devolution.product_price = this.formatAmount(Number(product[0].price) / product[0].quantity, 2)
            },
            setPaymethod(method) {
                
                if(this.sale_transaction_types.length >= 1) return

                let  transaction_type = this.transaction_options.find( transaction => transaction.id == method)
                let efective_option = []
                transaction_type.label = transaction_type.label.toLowerCase();
                
                efective_option = this.sale_transaction_types.filter( transaction => transaction.type == 'efectivo')
                
                if(efective_option[0] && (efective_option[0].type === transaction_type.label)) return

                switch(transaction_type.label) {
                    case 'efectivo':
                        this.sale_transaction_types.push({ type: 'efectivo', amount: null, money_received: null, effective_change: null, error: null, id_transaction_type:transaction_type.id })
                        break;
                    case 'debito':
                        this.sale_transaction_types.push({type: 'debito', numbers_card: null, amount: null, code: null, error: null, id_transaction_type: transaction_type.id })
                        break;
                    case 'credito':
                        this.sale_transaction_types.push({ type: 'credito', numbers_card: null, amount: null, code: null, error: null, id_transaction_type:transaction_type.id})
                        break;
                    default:
                        break;
                }

                this.transaction_type = null

                console.log('collection:', this.sale_transaction_types)
                
            },
            removeTransactionType(index) {
                delete this.sale_transaction_types[index]
                this.sale_transaction_types = this.sale_transaction_types.filter( transaction => true)
            }
            
        },

        computed: {
            ...mapGetters({
                search_result: 'box_operations_module/getSearchResult',
                devolution_item: 'changes_module/getSale',
                getTransactions: 'transaction_types/getTransactions',
            })
        },
        async created() {
            try {
                //transaction types options
                await this.$store.dispatch('transaction_types/listTransactionsTypes', { datatable: false})
                this.transaction_options = this.getTransactions.map( transaction => {
                    return { id: transaction.id_transaction_type, label: transaction.transaction_name}
                })
            }

            catch(error) {
                console.error(error)
            }
        }

        
    };
</script>

<style lang="scss" scoped>

</style>