<template>
    <div id="Pricing" class=" text-dark container-fluid bg-white shadow-sm px-3 mb-5 rounded border-box">

        <div class="row">

            <div class="col-12 my-2 pt-2">
                <div class="row bg-lightgray py-3">
                    <div class="col-3 opensans-bold">
                        <ki-input @input="search"
                            label="Buscar:"
                            v-model="search_query"
                            placeholder="Ej: Rafael Pérez"
                            full>

                        </ki-input>
                    </div>
                </div>
            </div>

            <div class="col-12 py-3" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p class="text-center opensans-bold">
                    Ha ocurrido un error intente más tarde
                </p>
            </div>
	        
	        <div class="col-12" v-if="!loading && empty">
		        <p class="text-center opensans-bold">
			        No hay resultados para esa búsqueda
		        </p>
	        </div>

            <div class="col-12 py-3" v-if="!loading && !fetch_error && !empty">
                <ki-table :data="lockbox_table_data"
                    :allow_headers="lockbox_table_headers"
                    no_pagination color_body="white" color_headers="primary"
                    color_headers_text="white"  border_headers="primary"
                    full>
                    <template v-slot:details="data">
                        <button @click.stop="showlockbox(data.item)"
                            class="btn btn-white pills"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:to_sale="data">
                        <button @click.stop="setLockbox(data)" v-if="data.item.active && !data.item.is_paid"
                            class="btn pills text-white opensans-bold"
                            style="background: #198754"
                            type="button">
                            Añadir pago
                        </button>
                        <button v-if="!data.item.active && !data.item.is_paid"
                            class="btn btn-danger pills text-white opensans-bold"
                            disabled
                            type="button">
                            Cancelado
                        </button>
                        <button v-if="data.item.is_paid"
                            class="btn pills text-white opensans-bold"
                            style="background: #198754"
                            type="button"
                            disabled>
                            Pagos completados
                        </button>
                    </template>
                    <template v-slot:cancel="data">
                        <button @click.stop="setDeleteLockbox(data.item)" v-if="data.item.active && !data.item.is_paid"
                            class="btn btn-white pills p-0"
                            style="width: 75px; height: 35px;"
                            type="button">
                            <img :src="trash_icon" />
                        </button>
                    </template>
                </ki-table>
            </div>

            <!----- Paginator -->
            <div class="col-12 pb-3">
                <div class="row justify-content-end align-items-center">
                    <div class="col d-flex">
                        <label class="mr-2">Productos por página: </label>
                        <ki-input @keyup.enter="updateRows"
                            v-model="rows">
                        </ki-input>
                    </div>

                    <div class="col-1  d-flex justify-content-end p-0">
                        <button @click.stop="prevPage"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                            <b-icon icon="arrow-left"
                            variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1" style="max-width: 80px;">
                        <ki-input @keyup.enter="goToPage"
                            v-model="current_page"
                            full>
                        </ki-input>
                    </div>

                    <div class="col-1 p-0" style="max-width: 65px">
                        <button @click.stop="nextPage"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="last_page">
                            <b-icon icon="arrow-right"
                                variant="white">
                            </b-icon>
                        </button>
                    </div>

                    <div class="col-1">
                        <p class="m-0">
                            de {{  pages  }}
                        </p>
                    </div>
                </div>
            </div>

        </div>

        <!--Modals-->
        <ki-modal v-if="details_modal">
            <div class="row bg-white position-relative p-3" style="border-radius: 15px">
                <button @click.stop="details_modal = false"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 25px; height: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); top: -10px; right: -10px;"
                    type="button">
                    X
                </button>

                <div class="col-12 opensans-bold px-0">
                    <p>
                        Detalles del apartado
                    </p>
                </div>

                <div class="col-12 px-0">
                    <ki-table :data="detail_table_data"
                        :allow_headers="detail_table_headers"
                        no_pagination color_body="white" color_headers="lightgray">
                    </ki-table>
                    <hr>
                </div>

                <div class="col 12 py-2 opensans-bold px-0">
                    <p class="m-0">
                        Resumen de pagos.
                    </p>
                </div>

                <div class="col-12 mt-2 px-0 mb-3">
                    <ki-table :data="payments_table_data"
                        :allow_headers="payments_table_headers"
                        no_pagination color_body="white" color_headers="lightgray">
                    </ki-table>
                </div>

                <div class="col-12 px-0 my-2">
                    <div class="row p-0 opensans-bold">
                        <div class="col-6 px-0">
                            <ki-input label="Total de pagos:"
                                v-model="current_payment"
                                type="number"
                                disabled
                                full>
                            </ki-input>
                        </div>
                        <div class="col-6 px-0 pl-2">
                            <ki-input label="Total por pagar:"
                                v-model="pending_payment"
                                type="number"
                                disabled
                                full>
                            </ki-input>
                        </div>
                    </div>
                </div>

                <div class="col-12 py-2 bg-lightgray" style="border-radius: 10px;">
                    <div class="row opensans-bold">
                        <div class="col-4">
                            <ki-input label="Subtotal:"
                                :disabled="true"
                                v-model="subtotal"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-4">
                            <ki-input label="IVA:"
                                :disabled="true"
                                v-model="iva"
                                full>
                            </ki-input>
                        </div>

                        <div class="col-4">
                            <ki-input label="Total:"
                                :disabled="true"
                                v-model="total"
                                full>
                            </ki-input>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 py-2 d-flex justify-content-end">
                    <button @click.stop="details_modal = false"
                        class="btn btn-primary text-white pills"
                        type="button">
                        Aceptar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="sale_modal">
            <div class="row bg-white p-3 text-dark position-relative" style="border-radius: 15px;">
                    <button @click.stop="sale_modal = false; sale_transaction_types = [];"
                        class="btn btn-white p-0 position-absolute"
                        style="width: 35px; height: 35px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0,0.3)"
                        type="button">
                        X
                    </button>
                

                <div class="col-12 opensans-bold">
                    <div class="row">
                        <div class="col-6">
                            <p>
                                Resumen del apartado
                            </p>
                        </div>

                        <div class="col-6">
                            <p class="d-flex justify-content-between">
                                Total por pagar:
                                <span class="opensans-bold" style="font-size: 20px;">
                                    ${{  subtotal  }} 
                                </span>
                            </p>
                        </div>
                    </div>
                    <hr>
                </div>
                
                <div class="col-12">
                    <ki-select label="Seleccionar forma de pago:" @change="setPaymethod(transaction_type)"
                        class="opensans-bold"
                        :options="transaction_options"
                        v-model="transaction_type"
                        full />
                    <span class="text-danger" v-if="errors.transaction_type">
                        {{ errors.transaction_type }}
                    </span>         
                    <hr>        
                </div>

                <div class="col-12 py-2" v-for="(transaction, index) in sale_transaction_types" :key="index">
                    <div v-if="transaction.type === 'efectivo'" class="position-relative row">

                        <button @click="removeTransactionType(index)"
                            class="btn btn-danger text-white p-0 position-absolute"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <hr>
                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.label }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="transaction.amount > pending_payment ?  transaction.amount = pending_payment : transaction.amount"
                                class="opensans-bold"
                                label="Cantidad a pagar:"
                                type="number"
                                v-model="transaction.amount"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @change="(transaction.effective_change = Number(transaction.money_received - transaction.amount).toFixed(2))"
                                @keyup.enter="(transaction.effective_change = Number(transaction.money_received - transaction.amount).toFixed(2))"
                                label="Cantidad recibida:"
                                class="opensans-bold"
                                type="number"
                                v-model="transaction.money_received"
                                full >
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Su cambio:"
                                type="number"
                                class="opensans-bold"
                                v-model="transaction.effective_change"
                                :disabled="true"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                    </div>

                    <div class="opensans-bold row position-relative" v-if="transaction.type === 'debito' || transaction.type === 'credito'" >
                        <hr>
                        <button @click.stop="removeTransactionType(index)"
                            class="btn btn-danger text-white position-absolute p-0"
                            style="width: 25px; height: 25px; top: 0px; right: 0px; z-index: 10;"
                            type="button">
                            X
                        </button>

                        <div class="col-12">
                            <p class="opensans-bold">
                                Método de pago: {{  transaction.label }}
                            </p>
                        </div>
                        
                        <div class="col-4">
                            <ki-input @input="transaction.amount > pending_payment ?  transaction.amount = pending_payment : transaction.amount"
                                label="Cantidad a pagar:"
                                class="pb-3"
                                placeholder="ej: 2330.00"
                                v-model="transaction.amount"
                                type="text"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                        <div class="col-4">
                            <ki-input label="Últimos cuatro números de tarjeta:"
                                class="pb-3"
                                placeholder="ej: 0945"
                                v-model="transaction.numbers_card"
                                type="number"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>

                        <div class="col-4">
                            <ki-input type="password" class="d-none"></ki-input>
                            <ki-input label="Código de autorización"
                                v-model="transaction.code"
                                type="password"
                                full>
                            </ki-input>
                            <span class="text-danger" v-if="transaction.error">
                                {{  transaction.error }}
                            </span>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end pt-3">
                        <button  @click.stop="sale_modal = false; sale_transaction_types = []"
                            class="btn btn-white pills"
                            type="button">
                            Cancelar
                        </button>
                        <button @click.stop="addPayment"
                            class="btn btn-primary text-white pills ml-2"
                            :disabled="sale_transaction_types.length == 0"
                            type="button">
                            procesar pago
                        </button>
                    </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_modal">
            <div class="row bg-white position-relative" style="border-radius: 10px;"> 
                <button @click.stop="delete_modal = false;"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3); right: -10px; top: -10px;"
                    type="button">
                    X
                </button>
                <div class="col-12 text-center">
                    <p class="opensans-bold p-3">
                        ¿Estas seguro de cancelar este apartado?
                    </p>
                </div>
                <div class="col-12 pb-3 d-flex justify-content-end">
                    <button @click.stop="delete_modal = false"
                        class="btn btn-primary text-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteLockbox"
                        class="btn btn-danger pills text-white ml-3"
                        type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>
    </div>
</template>

<script>

    //helpers
    import { mapGetters } from 'vuex';

    //icons
    import trash_icon from 'ASSETS/icons/icon_delete_red';

    export default {
        data() {
            return {
                trash_icon,
                lockbox_table_headers: [
                    { key: 'client_name', label: 'Nombre'},
                    { key: 'client_phone', label: 'Teléfono'},
                    { key: 'current_payment', label: 'Pagado'},
                    { key: 'pending_payment', label: 'Pendiente'},
                    { key: 'total', label: 'Total'},
                    { key: 'details'},
                    { key: 'to_sale'},
                    { key:'cancel'}
                ],
                lockbox_table_data: [],
                detail_table_headers: [
                    { key: 'product_name', label: 'Nombre'},
                    { key: 'upc', label: 'Código'},
                    { key: 'quantity', label: 'Cantidad'},
                    { key: 'price_normal', label: 'P. Original'},
                    { key: 'price', label: 'Precio'},
                    { key: 'discount', label: 'Descuento'},
                    { key: 'subtotal', label: 'Subtotal'},
                    { key: 'iva', label: 'IVA'},
                    { key: 'total', label: 'Total'}
                ],
                detail_table_data: [],
                payments_table_data: [],
                payments_table_headers: [
                    { key: 'amount', label: 'Cantidad'},
                    { key: 'transaction_name', label: 'Método de pago'},
                    { key: 'effective_change', label: 'Cambio'}
                ],
                details_modal: false,
                sale_modal: false,
                fetch_error: false,
                loading: true,
                empty: false,
                id_pricing: null,
                subtotal: null,
                iva: null,
                total: null,
                to_pay: null,
                transaction_options: [],
                sale_transaction_types: [],
                transaction_type: null,
                errors: {
                    transaction_type: null,
                    email: null,
                    amount: null
                },
                selected_id: null,
                delete_modal: false,
                current_payment: null,
                pending_payment: null,
	            //paginator
	            current_page: 1,
	            rows: 10,
	            search_query: ''
            }
        },
        computed: {
            ...mapGetters({
                pages: 'lockbox_module/getTotalPages',
	            total_rows: 'lockbox_module/getTotalRows',
                last_page: 'lockbox_module/getLastPage',
                getTransactions: 'transaction_types/getTransactions',
                getLockboxes: 'lockbox_module/getLockboxes',
                getLockbox: 'lockbox_module/getLockbox',
                getAccess: 'getAccess'
            })
        },
        methods: {
            //helpers
            formatAmount(value, decimals = 2) {
			    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
		    },
            composeTableData(){
                this.lockbox_table_data = this.getLockboxes.map( lockbox => {
                    return {
                        client_name: lockbox.client_name,
                        client_phone: lockbox.client_phone,
                        current_payment: lockbox.current_payment,
                        pending_payment: lockbox.pending_payment,
                        total: lockbox.total,
                        details: '-',
                        to_sale: '-',
                        id_sale: lockbox.id_sale,
                        cancel: '-',
                        active: lockbox.active,
                        is_paid: lockbox.is_paid
                    }
                })
            },

            //lockbox
            async showlockbox(lockbox) {
                await this.$store.dispatch('lockbox_module/viewLockbox', {id_sale: lockbox.id_sale})
                console.log('lockbox:', lockbox)
                this.detail_table_data = this.getLockbox.product_list.map( product => {
                    return {
                        product_name: product.product_name,
                        upc: product.upc,
                        quantity: product.quantity,
                        price_normal: product.price_normal,
                        price: product.price,
                        discount: product.discount,
                        iva: product.iva,
                        subtotal: product.subtotal,
                        total: product.total
                    }
                })

                this.payments_table_data = this.getLockbox.payments.map( payment => {
                    return {
                        transaction_name: payment.transaction_name,
                        amount: payment.amount,
                        effective_change: payment.effective_change
                    }
                })

                this.subtotal = this.getLockbox.subtotal
                this.iva = this.getLockbox.iva
                this.total = this.getLockbox.total
                this.current_payment = this.getLockbox.current_payment
                this.pending_payment = this.formatAmount(this.total - this.current_payment, 2)
                this.details_modal = true
            },
            setLockbox(data) {
                this.subtotal = data.item.pending_payment
                this.iva = data.item.iva
                this.selected_id = data.item.id_sale
                this.pending_payment = data.item.pending_payment
                this.sale_modal = true
            },
            setPaymethod(method) {
                
                if(this.sale_transaction_types.length >= 1) return

                let transaction_type = this.transaction_options.find(transaction => transaction.id == method)

                let efective_option = this.sale_transaction_types.find( transaction => transaction.type == 'efectivo')
                console.log(transaction_type, 'type');
                console.log(efective_option, 'efective')
                if(efective_option  && efective_option.type === transaction_type.type) return

                switch(transaction_type.type) {
                    case 'efectivo':
                        this.sale_transaction_types.push({label: 'EFECTIVO', type: 'efectivo', amount: null, money_received: null, effective_change: null, error: null, id_transaction_type:transaction_type.id })
                        break;
                    case 'debito':
                        this.sale_transaction_types.push({label: 'DEBITO', type: 'debito', numbers_card: null, amount: null, code: null, error: null, id_transaction_type: transaction_type.id })
                        break;
                    case 'credito':
                        this.sale_transaction_types.push({label: 'CREDITO', type: 'credito', numbers_card: null, amount: null, code: null, error: null, id_transaction_type:transaction_type.id})
                        break;
                    default:
                        console.log(transaction_type.type)
                        break;
                }

                this.transaction_type = null

                console.log('collection:', this.sale_transaction_types)
                
            },
            removeTransactionType(index) {
                delete this.sale_transaction_types[index]
                this.sale_transaction_types = this.sale_transaction_types.filter( transaction => true)
            },
            setDeleteLockbox(data) {
                this.selected_id = data.id_sale;
                this.delete_modal = true;
            },
            async deleteLockbox() {
                await this.$store.dispatch('lockbox_module/cancelLockbox', { id_sale: this.selected_id});

                await this.$store.dispatch('lockbox_module/listLockboxes', { datatable: true, page: 1, rows: 10})
                this.composeTableData();

                this.delete_modal = false;
            },
            async addPayment(){

                this.errors.amount = ""

                if(this.pending_payment < this.sale_transaction_types[0].amount)  {
                    this.sale_transaction_types[0].amount = this.pending_payment
                }
                
                const payload = {
                    transaction: this.sale_transaction_types[0],
                    id_sale: this.selected_id
                }

                await this.$store.dispatch('lockbox_module/payment', payload)
	            
	            this.sale_transaction_types = []
	            this.selected_id = null;
	            await this.navigate()
                this.sale_modal = false
            },

            //Paginator
	        async navigate(){
		        this.empty = false
		        this.loading = true
		        
		        if(this.current_page > this.total_pages){
			        this.current_page = this.total_pages == 0 ? 1 : this.current_page;
		        }
		        
		        await this.$store.dispatch('lockbox_module/listLockboxes', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
		        
		        if(this.getLockboxes.length == 0) {
					console.log('here')
			        this.empty = true;
					this.loading = false
			        return
		        }
		        
		        else {
			        this.lockbox_table_data = this.getLockboxes
			        this.composeTableData();
		        }
		        
		        this.loading = false
		        
	        },
            async nextPage() {
                this.current_page++

                await this.navigate();
                

            },
            async prevPage () {
                if(this.current_page == 1) return

                this.current_page--
				await this.navigate();
            
                

            },
            async updateRows() {
                await this.navigate()
                

            },

            //search
            async search() {
                await this.navigate()
                
            }

        },
        async created() {
            try {
                //Access
                this.actions = this.setAccess(this.getAccess, 'Ventas', 'Caja', 'Apartados');
                
                //transaction types options
                await this.$store.dispatch('transaction_types/listTransactionsTypes', {datatable: false})
                this.transaction_options = this.getTransactions.map( transaction => {
                    return { id: transaction.id_transaction_type, label: transaction.transaction_name, type: transaction.transaction_name.toLowerCase() }
                })

                //lockboxes list
                await this.navigate();

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.log(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>