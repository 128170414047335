<template>
    <section :id="tab_id" class="row bg_gray btn-pill">
        <div :class="`col p-0`" v-for="value in data" :key="value">

            <button @click="clickChange(value)"
                :class="[`btn btn-pill btn-${size} full-width`, 
                (btn_selected == value) ? `btn-${color}` : '']">

                    <span v-if="value.key !== undefined">
                        {{ value.label }}    
                    </span>
                    <span v-else>
                        {{ value }}    
                    </span>
                    
            </button>

        </div>
    </section>
</template>

<script>
	export default {
		name: 'ki-pillstabs',
        props: {
            tab_id: {
                type: String,
                required: false,
                default: 'tab-1',
            },
            data: {
                type: Array,
                required: true
            },
            color: {
                type: String,
                description: 'defines the focus color of the inputs',
                required: false,
                default: 'primary'
            },
            size: {
                type: String,
                required: false,
                default: 'sm'
            }
        },
        data() {
            return {
                btn_selected: null
            }
        },
        methods: {
            clickChange(value) {
                this.btn_selected = value
                this.$emit('change', this.btn_selected)
            }
        },
        created() {
            this.btn_selected = this.data[0]
        }
	}
</script>

<style lang="scss">
	.tab {
        
	}
</style>