<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box" style="min-height: 80vh">
       
        <pills-tabs :data="elements_tab" @change="print($event)" color="primary" class="my-2"></pills-tabs>

        <div v-if="element_active == 'Ventas'">
            <div class="col-12 p-0">
                <span class="text-black opensans-bold ki--description-sm">Buscar por:</span>
            </div>
            <div class="col-12 p-0">
                <div class="row bg_gray px-0 py-2">
                    <div class="col-12 p-0">
                        <div class="row p-0">
                            <div class="col col-xxl-2 p-0" >
                                <ki-input label="Número de nota"
                                    type="number"
                                    placeholder="Número de nota"
                                    class="opensans-bold pl-1 pt-1"
                                    full>
                                </ki-input>
                            </div>
                            <div class="col col-xxl-2 p-0">
                                <ki-input label="Detalles"
                                    type="text"
                                    class="opensans-bold pt-1 pl-1"
                                    placeholder="Información adicional"
                                    full></ki-input>
                            </div>
                            <div class="col-2 col-xxl-1 p-0">
                                <div class="h-100 d-flex justify-content-center align-items-end">
                                    <button class="pills bg-white"> 
                                        <div class="d-flex justify-content-center align-items-center">
                                            <img :src="icon_search" style="max-width:18px;" class="">
                                            <span class="pl-2">Buscar</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 mt-3 border-left border-bottom">
                <ki-table :data="note_table_data"
                    :allow_headers="note_table_headers"
                    color_headers="white" color_body="white"
                    no_pagination></ki-table>
            </div>
            <div class="col-12 p-0 mt-3 border-left border-bottom bg_gray">
                <ki-table :data="note_description_data"
                    :allow_headers="note_description_headers"
                    no_pagination color_headers="white" color_body="white">
                </ki-table>
            </div>
            <div class="col-12 p-0 mt-3">
                <div class="row p-0 col d-flex justify-content-end align-items-center">
                    <div>
                        <button class="pills bg-white px-5 mr-2">Button</button>
                        <button class="pills bg-primary-dark px-5 text-white">Button</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="element_active == 'Traspasos'">
            <div class="col-12 p-0">
                <span class="text-black opensans-semibold ki--description-sm">Buscar por:</span>
            </div>
            <div class="col-12 p-0">
                <div class="row bg_gray px-0 py-2">
                    <div class="col-12 p-0">
                        <div class="row p-0">
                            <div class="col-5 p-0" style="max-width:200px">
                                <div class="row pl-2">
                                    <div class="col-12 p-0 mt-1">
                                        <ki-input class="opensans-semibold" label="Número de traspaso:" type="number" placeholder="Número de traspaso" full>
                                        </ki-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 p-0">
                                <div class="h-100 d-flex justify-content-center align-items-end">
                                    <button class="pills bg-white"> 
                                        <div class="d-flex justify-content-center align-items-center">
                                            <img :src="icon_search" style="max-width:18px;" class="">
                                            <span class="pl-2">Buscar</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0 mt-3 border-left border-bottom">
                <ki-table :data="transfer_table_data"
                    :allow_headers="transfer_table_headers"
                    no_pagination color_headers="white" color_body="white">
                </ki-table>
            </div>
            <div class="col-12 p-0 mt-3 border-left border-bottom bg_gray">
                <ki-table :data="transfer_description_data"
                    :allow_headers="transfer_description_headers"
                    no_pagination color_headers="gray" color_body="gray">
                </ki-table>
            </div>
            <div class="col-12 p-0 mt-3">
                <div class="row p-0 col d-flex justify-content-end align-items-center">
                    <div>
                        <button class="pills bg-white px-5 mr-2">Button</button>
                        <button class="pills bg-primary-dark px-5 text-white">Button</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //Components
    import PillsTabs from '../../../components/PillsTabs.vue'

    //Icons
    import icon_search from '../../../assets/icons/icon_search.png'

export default {
    name: 'ki-reprint',
    components: {
        'pills-tabs': PillsTabs,
    },
    data() {
        return {
            elements_tab: ['Ventas', 'Remisiones', 'Traspasos'],
            element_active: 'Ventas',
            icon_search,
            note_table_headers: [
                {key: 'note_number', label: 'Número'},
                {key: 'date', label: 'Fecha'},
                {key: 'total', label: 'Total'},
                {key: 'efective', label: 'Efectivo'},
                {key: 'card', label: 'Tarjeta'},
                {key: 'check', label: 'Cheque'},
                {key: 'client_name', label: 'Nom. cliente'},
                {key: 'user', label: 'Usuario'},
                {key: 'credit', label: 'credito'},
                {key: 'invoice', label: 'Factura'},
                {key: 'details', label: 'Detalles'}
            ],
            note_table_data: [
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'},
                {note_number:"-", date: '-', total: '-', card: '.', efective: '-', check: '-', client_name: '-', user: '-', credit: '-', invoice: '-', details: '-'}
            ],
            note_description_headers: [
                { key: 'id', label: 'ID'},
                { key: 'code', label: 'Código'},
                { key: 'name', label: 'Nombre'},
                { key: 'price', label: 'Precio'},
                { key: 'quantity', label: 'Cantidad'},
                { key: 'subtotal', label: 'Subtotal'},
                { key: 'total', label: 'Total'},
                { key: 'date', label: 'Fecha'},
            ],
            note_description_data: [
                {id: '-', code: '-', name: '-', price: '-', quantity: '-', subtotal: '-', total: '-', date: '-'}
            ],
            transfer_table_headers: [
                { key: 'id', label: 'ID'},
                { key: 'total', label: 'Total'},
                { key: 'date', label: 'Fecha'},
                { key: 'branch', label: 'Sucursal'},
            ],
            transfer_table_data: [
                {id: '-', total: '-', date: '-', branch: '-'},
                {id: '-', total: '-', date: '-', branch: '-'},
                {id: '-', total: '-', date: '-', branch: '-'},
                {id: '-', total: '-', date: '-', branch: '-'},
                {id: '-', total: '-', date: '-', branch: '-'},
                {id: '-', total: '-', date: '-', branch: '-'}
            ],
            transfer_description_headers: [
                { key: 'code', label: "Código"},
                { key: 'name', label: "Nombre"},
                { key: 'price', label: "Precio"},
                { key: 'quantity', label: "Cantidad"}     
            ],
            transfer_description_data: [
                {code: '-', name: '-', price: '-', quantity: '-'}
            ]
        };
    },
    methods: {
        print(event_value) {
            this.element_active = event_value
        }
    },
};
</script>

<style lang="scss" scoped>

</style>