<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box" style="min-height: 80vh">
	    
        <div class="col-12 p-0 mt-3">
            <div class="row bg_gray py-2 px-2 ">
                <div class="col p-0">
                    <ki-input label="Cantidad incial/Fondo"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        placeholder="00:00"
                        v-model="initial_cash"
                        :disabled="true"
                        full>
                    </ki-input>
                </div>
                <div class="col p-0">
                    <ki-input label="Efectivo"
                        class="opensans-semibold pt-1 pl-1"
                        v-model="efective"
                        placeholder="00:00"
                        type="number"
                        :disabled="true"
                        full>
                    </ki-input>
                </div>
                
                <div class="col p-0">
                    <ki-input label="Tarjetas"
                        placeholder="00:00"
                        v-model="cards"
                        :disabled="true"
                        class="opensans-semibold pt-1 pl-1"
                        type="number"
                        full>
                    </ki-input>
                </div>
                <div class="col-3 col-xxl p-0">
                    <ki-input label="Total"
                        placeholder="Total"
                        class="opensans-semibold pt-1 pl-1"
                        :disabled="true"
                        v-model="total"
                        type="number"
                        full>
                    </ki-input>
                </div>
            </div>
        </div>

        <div class="col-12 p-0 mt-2" style="max-height: 400px; overflow-y: scroll">
            <ki-table :data="boxcut_table_data"
                :allow_headers="boxcut_table_headers"
                no_pagination color_headers="white" color_body="white">
	            <template v-slot:payments="data">
		            <p v-for="(payment, index) in data.item.payments" :key="`payment-${index}`">
			            {{ payment.transaction_name}}
		            </p>
	            </template>
	            <template v-slot:creation="data">
		            {{ new Date(data.item.creation).toLocaleTimeString() }}
	            </template>
            </ki-table>
        </div>

        <div class="col-12 p-0 mt-3 d-flex justify-content-end align-items-center">
            <button @click.stop="boxcut_modal = true"
                class="pills bg-primary-dark px-4 text-white">
                Procesar corte de caja
            </button>
        </div>

        <!--Boxcut modal-->
        <ki-modal v-if="boxcut_modal">
            <form class="row bg-white p-3 position-relative" style="border-radius: 15px;" autocomplete="off">
                <button @click.stop="boxcut_modal = false; password = null"
                    class="btn btn-white p-0 position-absolute"
                    style="width: 25px; height: 25px; box-shadow: -5px 5px 10px rgba(0,0,0,0.4); top: -10px; right: -10px;"
                    type="button">
                    X
                </button>
                <div class="col-12">
                    <p class="text-dark opensans-bold">
                        Procesar corte de caja
                    </p>
                </div>
                <div class="col-12">
                    <ki-input label="Nombre de usuario:"
                        class="opensans-bold"
                        v-model="username"
                        type="text"
                        :disabled="true"
                        full>
                    </ki-input>
                </div>
	            <div class="col-12">
		            <ki-input label="Total de caja:"
                        v-model="deposit_amount"
                        class="opensans-bold"
                        type="number"
                        full>
		            </ki-input>
		            <span class="text-danger" v-if="error_deposit_amount">
                        {{ error_deposit_amount }}
                    </span>
	            </div>
                <div class="col-12">
                    <ki-input label="Contraseña:"
                        v-model="password"
                        class="opensans-bold"
                        type="password"
                        full>
                    </ki-input>
                    <span class="text-danger" v-if="error_password">
                        {{ error_password}}
                    </span>
                </div>
                <div class="col-12 pt-3 d-flex justify-content-end">
                    <button @click.stop="processBoxcut"
                        class="btn btn-primary text-white pills"
                        type="button">
                        Procesar
                    </button>
                </div>
            </form>
        </ki-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ki-boxcut',

    data() {
        return {
            user: 'Seleccione',
            boxcut_table_data: [],
            boxcut_table_headers: [
                { key: 'id_sale', label:'ID'},
	            { key: 'quantity_product_total', label: 'C. Productos'},
                { key: 'subtotal', label:'Subtotal'},
	            { key: 'discount_total', label: 'Descuentos'},
                { key: 'iva', label: 'IVA'},
                { key: 'total', label: 'Total'},
                { key: 'creation', label: 'Fecha'},
	            { key: 'payments', label: 'Método'}
            ],
            boxcut_modal: false,
            password: null,
	        deposit_amount: null,
            username: null,
            error_password: null,
	        error_deposit_amount: null,
            initial_cash: null,
            efective: null,
            cards: null,
            total: null
        };
    },
    computed: {
        getSession() {
            return this.$store.getters.getSession
        },
	    ...mapGetters({
		    status: 'box_operations_module/getStatus',
		    getCashboxdata: 'box_operations_module/getCashboxdata',
	    })
    },

    methods: {
        async processBoxcut() {
            this.error_password = null

            if(_.isNull(this.password) || _.isEmpty(this.password)) {
                this.error_password = '*Campo obligatorio'
                return
            }
	        
	        if(_.isNull(this.deposit_amount)) {
		        this.error_deposit_amount = '*Campo obligatorio'
		        return
	        }
			
			const data = {
				body: {
					username: this.username,
					password: this.password,
					deposit_amount: this.formatAmount(this.deposit_amount)
				},
				query: {
					id_cashbox: this.status.cashbox_data.id_cashbox
				}
			}
	        
            await this.$store.dispatch('box_operations_module/closeBox', data)
            this.boxcut_modal = false
            this.password = null

        }
    },
	async mounted() {
		this.username = this.getSession.username
		
		try {
			await this.$store.dispatch('box_operations_module/checkStatus')
			await this.$store.dispatch('box_operations_module/checkCashbox')
			
			this.initial_cash = this.getCashboxdata.cashbox_data.cashbox_fund;
			this.efective = this.getCashboxdata.summary.cash
			this.cards = this.getCashboxdata.summary.card
			this.total = this.getCashboxdata.summary.total
			this.boxcut_table_data = this.getCashboxdata.sales
			console.log(this.status)
		}
		
		catch(e) {
		
		}
	},
	
};
</script>

<style lang="scss" scoped>

</style>